import RootMenu from "./RootMenu";

export default class RootIndex {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=RootIndex]").forEach((element) => {
            new RootIndex(element);
        });
    }

    private rootIndexElement: HTMLElement;                                     // 基準要素

    private constructor(rootIndexElement: HTMLElement) {
        this.rootIndexElement = rootIndexElement;

        RootMenu.initComponent(this.rootIndexElement);
    }
}
