import ObjectUtils from "../lib/ObjectUtils";

export default class MailTemplateBunrui {
    public static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLSelectElement>("[data-action=MailTemplateBunrui]").forEach((element) => {
            new MailTemplateBunrui(element, parentElement);
        });
    }

    private mailTemplateBunruiElement: HTMLSelectElement;
    private parentElement: HTMLElement;

    private constructor(mailTemplateBunruiElement: HTMLSelectElement, parentElement: HTMLElement) {
        this.mailTemplateBunruiElement = mailTemplateBunruiElement;
        this.parentElement = parentElement;

        this.mailTemplateBunruiElement.addEventListener('change', event => this.handleChange(event));
    }

    private async handleChange(event: Event) {
        let option = this.mailTemplateBunruiElement.selectedOptions[0];
        let url = option.dataset["url"]
        if (!(url)) {
            return;
        }

        let mailTemplateIdElement = ObjectUtils.require(this.parentElement.querySelector("#mailTemplateId"), HTMLElement);

        let response = await fetch(url);
        let restponeText = await response.text();

        mailTemplateIdElement.innerHTML = restponeText;
    }
}