import ClickTrigger from "../action/ClickTrigger";
import ConfirmSubmitButton from "../action/ConfirmSubmitButton";

class HoshoMoshikomiOrderEdit {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=HoshoMoshikomiOrderEdit]").forEach((element) => {
            new HoshoMoshikomiOrderEdit(element);
        });
    }

    private hoshoMoshikomiOrderEditElement: HTMLElement;

    private constructor(hoshoMoshikomiOrderEditElement: HTMLElement) {
        this.hoshoMoshikomiOrderEditElement = hoshoMoshikomiOrderEditElement;
        ConfirmSubmitButton.initComponent(this.hoshoMoshikomiOrderEditElement);
        ClickTrigger.initComponent(this.hoshoMoshikomiOrderEditElement);
    }

}

export default HoshoMoshikomiOrderEdit;