import ChangeSagyoKomoku from "../action/ChangeSagyoKomoku";
import ChangeSeisanduki from "../action/ChangeSeisanduki";
import ReloadingModalDialog from "../action/ReloadingModalDialog";

class ShuriOrderJisshiEditModal {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=ShuriOrderJisshiEditModal]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
            new ShuriOrderJisshiEditModal(element, parentElement);
        });
    }

    private shuriOrderJisshEditModalElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(shuriOrderJisshEditModalElement: HTMLElement, parentElement: HTMLElement) {
        this.shuriOrderJisshEditModalElement = shuriOrderJisshEditModalElement;
        this.parentElement = parentElement;

        this.shuriOrderJisshEditModalElement.addEventListener('ReloadingModalDialog:reloaded', event => this.handleReloaded(event));
    }

    private handleReloaded(event: Event) {
        ChangeSeisanduki.initComponent(this.shuriOrderJisshEditModalElement);
        ChangeSagyoKomoku.initComponent(this.shuriOrderJisshEditModalElement);
    }
}

export default ShuriOrderJisshiEditModal;
