import ClickTrigger from "../action/ClickTrigger";

export default class HoshoMoshikomiOrderNew {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=HoshoMoshikomiOrderNew]").forEach((element) => {
            new HoshoMoshikomiOrderNew(element);
        });
    }

    private hoshoMoshikomiOrderNewElement: HTMLElement;

    private constructor(hoshoMoshikomiOrderNewElement: HTMLElement) {
        this.hoshoMoshikomiOrderNewElement = hoshoMoshikomiOrderNewElement;
        ClickTrigger.initComponent(this.hoshoMoshikomiOrderNewElement);
    }
}