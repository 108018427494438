import ObjectUtils from "../lib/ObjectUtils";

export default class ChangeHikiwatashiYotei {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLSelectElement>("[data-action=ChangeHikiwatashiYotei]").forEach((element) => {
            new ChangeHikiwatashiYotei(element, parentElement);
        });
    }

    private changeHikiwatashiYoteiElement: HTMLSelectElement;                               // 基準要素
    private parentElement: HTMLElement;                                                     // 親要素

    private constructor(changeHikiwatashiYoteiElement: HTMLSelectElement, parentElement: HTMLElement) {
        this.changeHikiwatashiYoteiElement = changeHikiwatashiYoteiElement;
        this.parentElement = parentElement;

        //「お引渡し予定」を変更した時にイベントを発生させる
        this.changeHikiwatashiYoteiElement.addEventListener('change', event => this.handleChange(event));

    }

    private handleChange(event: any): any {


        let okurisakiDairiten = ObjectUtils.require(this.parentElement.querySelector("#okurisakiDairitenId"), HTMLSelectElement);
        okurisakiDairiten.selectedIndex = -1;

        this.parentElement.querySelectorAll<HTMLOptionElement>("[data-dairiten-kubun]").forEach((element) => {

            // ウィズの場合
            if (element.dataset["dairitenKubun"] === "WITH") {
                if (this.isJitakuChokuso(event.currentTarget.value)) {
                    let index = okurisakiDairiten.querySelector<HTMLOptionElement>("[data-dairiten-kubun='WITH']").index;

                    // ウィズを表示、自動選択する
                    element.classList.remove("d-none");
                    okurisakiDairiten.selectedIndex = index;
                } else {
                    // 代理店を非表示
                    element.classList.add("d-none");
                }

            // ウィズ以外の代理店の場合
            } else {
                if (this.isDairiten(event.currentTarget.value)) {
                    // 代理店を表示
                    element.classList.remove("d-none");
                } else {
                    // ウィズを非表示
                    element.classList.add("d-none");
                }
            }
        });
    }

    private isJitakuChokuso(value: String) {
        return value == "JITAKU_CHOKUSO";
    }

    private isDairiten(value: String) {
        return value == "DAIRITEN";
    }
}
