import DateUtils from "../lib/DateUtils";
import ObjectUtils from "../lib/ObjectUtils";

export default class SeisanCheckIndexShoninCheckbox {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action~=SeisanCheckIndexShoninCheckbox]").forEach(element => {
            new SeisanCheckIndexShoninCheckbox(element, parentElement);
        });
    }

    private seisanCheckIndexShoninCheckboxElement: HTMLInputElement;
    private parentElement: HTMLElement;

    private constructor(seisanCheckIndexShoninCheckboxElement: HTMLInputElement, parentElement: HTMLElement) {
        this.seisanCheckIndexShoninCheckboxElement = seisanCheckIndexShoninCheckboxElement;
        this.parentElement = parentElement;

        this.seisanCheckIndexShoninCheckboxElement.addEventListener('change', event => this.handleChange(event))
    }

    /**
     * 承認チェックをクリックしたときの処理
     *
     * チェックされたとき精算月から精算日（翌１０日）を設定する。
     * チェックが外されたとき精算日をクリアする。
     */
    private handleChange(event: Event) {

        let p: HTMLElement = this.seisanCheckIndexShoninCheckboxElement;

        while (!p.dataset["jisshiTesuryoId"]) {
            p = p.parentElement;
        }

        let seisanbiElement = ObjectUtils.require(p.querySelector("[name=seisanbi]"), HTMLInputElement);

        if (this.seisanCheckIndexShoninCheckboxElement.checked) {
            let seisandukiElement = ObjectUtils.require(p.querySelector("[name=seisanduki]"), HTMLInputElement);

            let d = DateUtils.parse(seisandukiElement.value + "-01");

            if (d instanceof Error) {
                return;
            }

            let nextMonth = d.getMonth() + 1;
            let nextYear = d.getFullYear();

            if (nextMonth > 11) {
                nextMonth = 0; // 月は0から始まるため1月は0
                nextYear += 1;
            }

            let nextMonthTenth = DateUtils.newInstance(nextYear, nextMonth, 10);

            seisanbiElement.value = DateUtils.toISODateString(nextMonthTenth);
        } else {
            seisanbiElement.value = "";
        }

    }
}