import ReloadingModalDialog from "../action/ReloadingModalDialog";
import ChangeSeisanduki from "../action/ChangeSeisanduki";
import ChangeSagyoKomoku from "../action/ChangeSagyoKomoku";

class ChumonJisshiNewModal {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=ChumonJisshiNewModal]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
            new ChumonJisshiNewModal(element, parentElement);
        });
    }

    private chumonJisshiNewModalElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(chumonJisshiNewModalElement: HTMLElement, parentElement: HTMLElement) {
        this.chumonJisshiNewModalElement = chumonJisshiNewModalElement;
        this.parentElement = parentElement;

        this.chumonJisshiNewModalElement.addEventListener('ReloadingModalDialog:reloaded', event => this.handleReloaded(event));
    }

    private handleReloaded(event: Event) {
        ChangeSeisanduki.initComponent(this.chumonJisshiNewModalElement);
        ChangeSagyoKomoku.initComponent(this.chumonJisshiNewModalElement);
    }
}

export default ChumonJisshiNewModal;
