import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";

export default class SearchYubinBango {

    public static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=SearchYubinBango]").forEach((element) => {
            new SearchYubinBango(element, parentElement);
        });
    }

    private yubinBangoElement: HTMLInputElement;
    private apiUrl: string;
    private todofuken: string;
    private shikuchoson: string;
    private choiki: string

    private parentElement: HTMLElement;


    private constructor(yubinBangoElement: HTMLInputElement, parentElement: HTMLElement) {
        this.yubinBangoElement = yubinBangoElement;
        this.parentElement = parentElement;

        this.apiUrl = StringUtils.require(this.yubinBangoElement.dataset["apiUrl"]);
        this.todofuken = StringUtils.require(this.yubinBangoElement.dataset["todofuken"]);
        this.shikuchoson = StringUtils.require(this.yubinBangoElement.dataset["shikuchoson"]);
        this.choiki = StringUtils.require(this.yubinBangoElement.dataset["choiki"]);

        this.yubinBangoElement.addEventListener('change', event => this.handleChange(event));
    }

    private async handleChange(event: Event) {

        let yubinBango = this.yubinBangoElement.value.replace("-", "");

        if (yubinBango.length != 7) {
            return
        }

        let params = new URLSearchParams({
            "yubinBango": yubinBango
        }).toString();

        let response = await fetch(this.apiUrl + "?" + params);

        let responseJson = await response.json();

        if (0 < responseJson.yubinBangoList.length) {
            let yubinBango = responseJson.yubinBangoList[0];

            let todofukenElement = ObjectUtils.require(this.parentElement.querySelector(this.todofuken), HTMLSelectElement);
            todofukenElement.value = yubinBango.todofuken;

            let shikuchosonElement = ObjectUtils.require(this.parentElement.querySelector(this.shikuchoson), HTMLInputElement);
            shikuchosonElement.value = yubinBango.shikuchoson;

            let choikiElement = ObjectUtils.require(this.parentElement.querySelector(this.choiki), HTMLInputElement);
            choikiElement.value = yubinBango.choiki;
        }
    }
}