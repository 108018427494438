import MailTemplateBunrui from "./MailTemplateBunrui";
import MailTemplateId from "./MailTemplateId";

export default class KokyakuMailNew {

    public static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=KokyakuMailNew]").forEach((element) => {
            new KokyakuMailNew(element);
        });
    }

    private kokyakuMailNewElement: HTMLElement;

    private constructor(kokyakuMailNewElement: HTMLElement) {
        this.kokyakuMailNewElement = kokyakuMailNewElement;

        MailTemplateBunrui.initComponent(this.kokyakuMailNewElement);
        MailTemplateId.initComponent(this.kokyakuMailNewElement);
    }
}
