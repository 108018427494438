import ReloadingModalDialog from "../action/ReloadingModalDialog";


class KokyakuJisshiShow {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=KokyakuJisshiShow]").forEach((element) => {
            new KokyakuJisshiShow(element);
        });
    }

    private kokyakuJisshiShowElement: HTMLElement;

    private constructor(kokyakuJisshiShowElement: HTMLElement) {
        this.kokyakuJisshiShowElement = kokyakuJisshiShowElement;

        ReloadingModalDialog.initComponent(this.kokyakuJisshiShowElement);

    }
}

export default KokyakuJisshiShow;

window.addEventListener('DOMContentLoaded', (event) => {
    KokyakuJisshiShow.initComponent();
});