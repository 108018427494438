import ReloadingModalDialog from "../action/ReloadingModalDialog";

class ChumonJisshShowModal {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=ChumonJisshShowModal]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
            new ChumonJisshShowModal(element, parentElement);
        });
    }

    private chumonJisshShowModalElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(chumonJisshShowModalElement: HTMLElement, parentElement: HTMLElement) {
        this.chumonJisshShowModalElement = chumonJisshShowModalElement;
        this.parentElement = parentElement;

        this.chumonJisshShowModalElement.addEventListener('ReloadingModalDialog:reloaded', event => this.handleReloaded(event));
    }

    private handleReloaded(event: Event) {
        ReloadingModalDialog.initComponent(this.chumonJisshShowModalElement);
    }
}

export default ChumonJisshShowModal;
