import NumberUtils from "../lib/NumberUtils";
import ObjectUtils from "../lib/ObjectUtils";

class ShuriOrderSeihinIppanPlusRemi {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=ShuriOrderSeihinIppanPlusRemi]").forEach((element) => {
            new ShuriOrderSeihinIppanPlusRemi(element, parentElement);
        });
    }

    private shuriOrderSeihinIppanPlusRemiElement: HTMLInputElement;

    private parentElement: HTMLElement;

    private constructor(shuriOrderSeihinIppanPlusRemiElement: HTMLInputElement, parentElement: HTMLElement) {
        this.shuriOrderSeihinIppanPlusRemiElement = shuriOrderSeihinIppanPlusRemiElement;
        this.parentElement = parentElement;

        this.shuriOrderSeihinIppanPlusRemiElement.addEventListener('change', event => this.handleChange(event));
    }

    private handleChange(event: Event) {
        // let ippanmoMainKojoShinkokuMoryoElement = ObjectUtils.require(this.parentElement.querySelector("#ippanmoMainKojoShinkokuMoryo"), HTMLInputElement);
        // let ippanmoSecondKojoShinkokuMoryoElement = ObjectUtils.require(this.parentElement.querySelector("#ippanmoSecondKojoShinkokuMoryo"), HTMLInputElement);

        let remiHairMainKojoShinkokuMoryoElement = ObjectUtils.require(this.parentElement.querySelector("#remiHairMainKojoShinkokuMoryo"), HTMLInputElement);
        let remiHairMainKojoShinkokuLossMoryoElement = ObjectUtils.require(this.parentElement.querySelector("#remiHairMainKojoShinkokuLossMoryo"), HTMLInputElement);
        let remiHairSecondKojoShinkokuMoryoElement = ObjectUtils.require(this.parentElement.querySelector("#remiHairSecondKojoShinkokuMoryo"), HTMLInputElement);
        let remiHairSecondKojoShinkokuLossMoryoElement = ObjectUtils.require(this.parentElement.querySelector("#remiHairSecondKojoShinkokuLossMoryo"), HTMLInputElement);

        // 実質レミー毛量(メイン)の計算
        let mainJisshitsuRemiMoryo = NumberUtils.parse(remiHairMainKojoShinkokuMoryoElement.value) + NumberUtils.parse(remiHairMainKojoShinkokuLossMoryoElement.value);
        let remiHairMainJisshituRemiMoryoElement = ObjectUtils.require(this.parentElement.querySelector("#remiHairMainJisshituRemiMoryo"), HTMLInputElement);
        remiHairMainJisshituRemiMoryoElement.value = mainJisshitsuRemiMoryo.toString();

        // 実質レミー毛量(セカンド)の計算
        let secondJisshitsuRemiMoryo = NumberUtils.parse(remiHairSecondKojoShinkokuMoryoElement.value) + NumberUtils.parse(remiHairSecondKojoShinkokuLossMoryoElement.value);
        let remiHairSecondJisshituRemiMoryoElement = ObjectUtils.require(this.parentElement.querySelector("#remiHairSecondJisshituRemiMoryo"), HTMLInputElement);
        remiHairSecondJisshituRemiMoryoElement.value = secondJisshitsuRemiMoryo.toString();

        // 工場申告一般＋レミー総量の計算
        // let ippanPlusRemi = NumberUtils.parse(ippanmoMainKojoShinkokuMoryoElement.value) + NumberUtils.parse(ippanmoSecondKojoShinkokuMoryoElement.value) + mainJisshitsuRemiMoryo + secondJisshitsuRemiMoryo;
        // let ippanPlusRemiElement = ObjectUtils.require(this.parentElement.querySelector("#kojoShinkokuIppanPlusRemiSoryo"), HTMLInputElement);
        // ippanPlusRemiElement.value = ippanPlusRemi.toString();
    }
}

export default ShuriOrderSeihinIppanPlusRemi;
