import * as objutils from "../lib/object-utils";

// BootStrap の初期化
const bootstrap = require('bootstrap');

export default class KokyakuBiko {
    public static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=KokyakuBiko]").forEach((element) => {
            new KokyakuBiko(element, parentElement);
        });
    }

    private kokyakuBikoElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(kokyakuBikoElement: HTMLElement, parentElement: HTMLElement) {
        this.kokyakuBikoElement = kokyakuBikoElement;
        this.parentElement = parentElement;

        this.kokyakuBikoElement.addEventListener('show.bs.modal', event => this.handleShowBsModal(event));
    }

    private async handleShowBsModal(event: any) {
        let relatedTarget = objutils.require<HTMLButtonElement>(event.relatedTarget);
        let bodyUrl = objutils.require<string>(relatedTarget.dataset["bodyUrl"]);
        let modalBody = objutils.require<HTMLElement>(this.kokyakuBikoElement.querySelector(".modal-body"));

        let response = await fetch(bodyUrl);
        let restponeText = await response.text();

        modalBody.innerHTML = restponeText;
    }
}