import ObjectUtils from "../lib/ObjectUtils";
import DateUtils from "../lib/DateUtils";

class ChangeSeisanduki {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action~=ChangeSeisanduki]").forEach((element) => {
            new ChangeSeisanduki(element, parentElement);
        });
    }

    private changeSeisandukiElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(changeSeisandukiElement: HTMLElement, parentElement: HTMLElement) {
        this.changeSeisandukiElement = changeSeisandukiElement;
        this.parentElement = parentElement;

        this.changeSeisandukiElement.addEventListener("change", event => this.handleChange(event));
    }

    private handleChange(event: any) {

        // 変更された日付の値を取得
        let targetValue = event.target.value;

        // 変更された日付を Date 型に変換
        let date = DateUtils.parse(targetValue);

        if (date instanceof Date) {
            // Date 型を文字列に変換して年月を取得
            let stringDate = DateUtils.toISOMonthString(date);

            // 精算月のエレメントを取得し、value 値を設定
            let seisandukiElement = ObjectUtils.require(this.parentElement.querySelector("#seisanduki"), HTMLInputElement);
            seisandukiElement.value = stringDate;
        }

    }
}

export default ChangeSeisanduki;