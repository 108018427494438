import ShuriOrderSeihinIppanPlusRemi from "./ShuriOrderSeihinIppanPlusRemi";


class ShuriOrderSeihinEdit {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=ShuriOrderSeihinEdit]").forEach((element) => {
            new ShuriOrderSeihinEdit(element);
        });
    }

    private shuriOrderSeihinEditElement: HTMLElement;

    private constructor(shuriOrderSeihinEditElement: HTMLElement) {
        this.shuriOrderSeihinEditElement = shuriOrderSeihinEditElement;

        ShuriOrderSeihinIppanPlusRemi.initComponent(this.shuriOrderSeihinEditElement);
    }
}

// 外部に公開するための定義
export default ShuriOrderSeihinEdit;