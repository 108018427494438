import ConfirmDialog from "../action/ConfirmDialog";
import * as objutils from "../lib/object-utils";

class ToiawaseIndexDelete {
    toiawaseIndexDeleteElement: HTMLInputElement;
    private pending: boolean = true;

    constructor(toiawaseIndexDeleteElement: HTMLInputElement) {
        this.toiawaseIndexDeleteElement = toiawaseIndexDeleteElement;

        this.toiawaseIndexDeleteElement.addEventListener("click", event => this.handleSubmit(event));
    }

    private handleSubmit(event: Event) {
        if (this.pending) {
            event.preventDefault();

            let message = objutils.require<string>(this.toiawaseIndexDeleteElement.dataset["message"]);
            ConfirmDialog.getInstance().show(message, event => this.handleComfirmed(event), event => this.handleCancel(event));
        }

        this.pending = true;
    }

    private handleComfirmed(event: Event) {
        this.pending = false;
        this.toiawaseIndexDeleteElement.click();
    }

    private handleCancel(event: Event) {
        this.pending = true;
    }
}

export default ToiawaseIndexDelete;