export default class BooleanUtils {

    static parse(s: string | undefined | null): boolean {
        if (s === undefined) {
            return false;
        }

        if (s === null) {
            return false;
        }

        return s === "1" || s === "true";
    }
}