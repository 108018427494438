import ConfirmSubmitButton from "../action/ConfirmSubmitButton";
import ChangeYoyakuShurui from "./ChangeYoyakuShurui";
import YoyakuHaisohin from "./YoyakuHaisohin";
import YoyakuShuruiKubun from "./YoyakuShuruiKubun";

class YoyakuEdit {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=YoyakuEdit]").forEach((element) => {
            new YoyakuEdit(element);
        });
    }

    private yoyakuEditElement: HTMLElement;

    private constructor(yoyakuEditElement: HTMLElement) {
        this.yoyakuEditElement = yoyakuEditElement;

        YoyakuHaisohin.initComponent(this.yoyakuEditElement);

        // 予約種類区分によって、表示する予約種類のオプションを変更する
        YoyakuShuruiKubun.initComponent(this.yoyakuEditElement);

        ChangeYoyakuShurui.initComponent(this.yoyakuEditElement);

        ConfirmSubmitButton.initComponent(this.yoyakuEditElement);
    }

}

window.addEventListener('DOMContentLoaded', (event) => {
    YoyakuEdit.initComponent();
});