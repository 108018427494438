import OrdermadeSeihinIppanPlusRemi from "./OrdermadeSeihinIppanPlusRemi";
import OrdermadeSeihinKamiSoryo from "./OrdermadeSeihinKamiSoryo";
import OrdermadeSeihinSeihinId from "./OrdermadeSeihinSeihinId";
import OrdermadeSeihinSeisakuNissu from "./OrdermadeSeihinSeisakuNissu";

class OrdermadeSeihinNew {
    static initComponent() {
        // ②data-action=OrdermadeSeihinNewを探す。見つけるごとにconstructorが動く
        // ここでのelementはdata-action=OrdermadeSeihinNewを持っているタグ
        document.querySelectorAll<HTMLElement>("[data-action=OrdermadeSeihinNew]").forEach((element) => {
            new OrdermadeSeihinNew(element);
        });
    }

    private ordermadeSeihinNewElement: HTMLElement;

    // ③初期化を行い、OrdermadeSeihinKamiSoryo.initComponentが動く
    private constructor(ordermadeSeihinNewElement: HTMLElement) {
        this.ordermadeSeihinNewElement = ordermadeSeihinNewElement;

        OrdermadeSeihinKamiSoryo.initComponent(this.ordermadeSeihinNewElement);
        OrdermadeSeihinIppanPlusRemi.initComponent(this.ordermadeSeihinNewElement);
        OrdermadeSeihinSeisakuNissu.initComponent(this.ordermadeSeihinNewElement);
        OrdermadeSeihinSeihinId.initComponent(this.ordermadeSeihinNewElement);
    }
}

// 外部に公開するための定義
export default OrdermadeSeihinNew;

// ①画面の読み込みが完了するとOrdermadeSeihinNew.initComponentが動く
window.addEventListener('DOMContentLoaded', (event) => {
    OrdermadeSeihinNew.initComponent();
});
