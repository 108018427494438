import ClickTrigger from "../action/ClickTrigger";
import ChumonRyokinKeisan from "./ChumonRyokinKeisan";
import ChumonRyokinKijunbi from "./ChumonRyokinKijunbi";
import ChumonShiharaiHoho from "./ChumonShiharaiHoho";

class SemiOrderEdit {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=SemiOrderEdit]").forEach((element) => {
            new SemiOrderEdit(element);
        });
    }

    private semiOrderEditElement: HTMLElement;

    private constructor(semiOrderEditElement: HTMLElement) {
        this.semiOrderEditElement = semiOrderEditElement;

        ChumonRyokinKijunbi.initComponent(this.semiOrderEditElement);
        ClickTrigger.initComponent(this.semiOrderEditElement);
        ChumonRyokinKeisan.initComponent(this.semiOrderEditElement);
        ChumonShiharaiHoho.initComponent(this.semiOrderEditElement);
    }
}

export default SemiOrderEdit;
