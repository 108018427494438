import ObjectUtils from "../lib/ObjectUtils";

class ClickTrigger {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=ClickTrigger]").forEach((element) => {
            new ClickTrigger(element, parentElement);
        });
    }

    private clickTriggerElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(clickTriggerElement: HTMLElement, parentElement: HTMLElement) {
        this.clickTriggerElement = clickTriggerElement;
        this.parentElement = parentElement;

        this.clickTriggerElement.addEventListener("click", event => this.handleClick(event));
    }

    private handleClick(event: Event) {
        let target = ObjectUtils.requireString(this.clickTriggerElement.dataset["target"]);
        let targetElement = ObjectUtils.require(this.parentElement.querySelector(target), HTMLElement);
        if (event.target !== targetElement) {
            targetElement.click();
        }
    }
}

export default ClickTrigger;