import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";

export default class ClientCheckCopyValue {

    public static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=copy-value]").forEach((element) => {
            new ClientCheckCopyValue(element);
        });
    }

    private clientCheckCopyValue: HTMLElement;                                     // 基準要素

    private constructor(clientCheckCopyValue: HTMLElement) {
        this.clientCheckCopyValue = clientCheckCopyValue;

        this.clientCheckCopyValue.addEventListener('click', event => this.copyAndInputValue(event));

    }

    // クリックした要素の値を検索項目に自動入力する
    private copyAndInputValue(event: any) {
        let currentTargetarget = StringUtils.require(event.currentTarget.dataset["target"]);
        let currentTargetargetValue = StringUtils.require(event.currentTarget.dataset["copyValue"]);
        let inputTarget = ObjectUtils.require(document.querySelector("#"+currentTargetarget), HTMLInputElement);
        inputTarget.value = currentTargetargetValue;
    }
}
