import NumberUtils from "../lib/NumberUtils";
import ObjectUtils from "../lib/ObjectUtils";

class OrdermadeSeihinKamiSoryo {
    static initComponent(parentElement: HTMLElement) {
        // ①OrdermadeSeihinNewから持ってきたparentElementからdata-action=OrdermadeSeihinKamiSoryoをもつエレメントを探す。
        // 見つけるごとにconstructorが動く
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=OrdermadeSeihinKamiSoryo]").forEach((element) => {
            new OrdermadeSeihinKamiSoryo(element, parentElement);
        });
    }

    private ordermadeSeihinKamiSoryoElement: HTMLInputElement;

    private parentElement: HTMLElement;

    // ordermadeSeihinKamiSoryoElement = data-action=OrdermadeSeihinKamiSoryoを持つエレメント(withSokuteiSoryo, kojoSokuteiSoryo, baseRyo)
    // parentElement = data-action=OrdermadeSeihinNewを持つエレメント(オーダーメイド製品管理登録)
    private constructor(ordermadeSeihinKamiSoryoElement: HTMLInputElement, parentElement: HTMLElement) {
        this.ordermadeSeihinKamiSoryoElement = ordermadeSeihinKamiSoryoElement;
        this.parentElement = parentElement;

        // ordermadeSeihinKamiSoryoElement(withSokuteiSoryo, kojoSokuteiSoryo, baseRyo) に変更があるとhandleChange が動く
        this.ordermadeSeihinKamiSoryoElement.addEventListener('change', event => this.handleChange(event));
    }

    // ordermadeSeihinKamiSoryoElement(withSokuteiSoryo, kojoSokuteiSoryo, baseRyo)に変更があるたびに動く
    private handleChange(event: Event) {
        // ObjectUtils.require
        let withSokuteiSoryoElement = ObjectUtils.require(this.parentElement.querySelector("#withSokuteiSoryo"), HTMLInputElement);
        let kojoSokuteiSoryoElement = ObjectUtils.require(this.parentElement.querySelector("#kojoSokuteiSoryo"), HTMLInputElement);
        let baseRyoElement = ObjectUtils.require(this.parentElement.querySelector("#baseRyo"), HTMLInputElement);

        // HTMLInputElement型から文字列で取り出したwithSokuteiSoryoElement, kojoSokuteiSoryoElementを数値に変え、計算したものをsokuteisaに追加
        let sokuteisa = NumberUtils.parse(withSokuteiSoryoElement.value) - NumberUtils.parse(kojoSokuteiSoryoElement.value);
        // sokuteisaElementにokuteisaを代入
        let sokuteisaElement = ObjectUtils.require(this.parentElement.querySelector("#sokuteisa"), HTMLInputElement);
        // withSokuteiSoryoとkojoSokuteiSoryoの計算結果のsokuteisaを文字列に変更し、sokuteisaElement.valueに代入
        sokuteisaElement.value = sokuteisa.toString();

        let kamiSoryo = NumberUtils.parse(kojoSokuteiSoryoElement.value) - NumberUtils.parse(baseRyoElement.value);
        let kamiSoryoElement = ObjectUtils.require(this.parentElement.querySelector("#kamiSoryo"), HTMLInputElement);
        kamiSoryoElement.value = kamiSoryo.toString();
    }
}

export default OrdermadeSeihinKamiSoryo;
