import ObjectUtils from "../lib/ObjectUtils";

export default class MailTemplateId {
    public static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLSelectElement>("[data-action=MailTemplateId]").forEach((element) => {
            new MailTemplateId(element, parentElement);
        });
    }

    private mailTemplateIdElement: HTMLSelectElement;
    private parentElement: HTMLElement;

    private constructor(mailTemplateIdElement: HTMLSelectElement, parentElement: HTMLElement) {
        this.mailTemplateIdElement = mailTemplateIdElement;
        this.parentElement = parentElement;

        this.mailTemplateIdElement.addEventListener('change', event => this.handleChange(event));
    }

    private async handleChange(event: Event) {
        let option = this.mailTemplateIdElement.selectedOptions[0];

        let url = option.dataset["url"]
        if (!(url)) {
            return;
        }

        let subjectElement = ObjectUtils.require(this.parentElement.querySelector("#subject"), HTMLInputElement);
        let bikoElement = ObjectUtils.require(this.parentElement.querySelector("#biko"), HTMLTextAreaElement);
        let kokyakuShimeiElement = ObjectUtils.require(this.parentElement.querySelector("#kokyakuShimei"), HTMLInputElement);
        let kinyushaElement = ObjectUtils.require(this.parentElement.querySelector("#kinyusha"), HTMLSelectElement);

        let response = await fetch(url);
        let responeJson = await response.json();

        subjectElement.value = responeJson.subject;

        let message = (responeJson.message as string);
        message = message.replace(/%%{\$tg.client_name}%%/g, kokyakuShimeiElement.value);
        message = message.replace(/%%{\$tg.with_name}%%/g, kinyushaElement.selectedOptions[0].text);
        bikoElement.value = message;
    }
}