import ObjectUtils from "../lib/ObjectUtils";

export default class SeisanCheckIndexUpdateButtonEnabler {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action~=SeisanCheckIndexUpdateButtonEnabler]").forEach(element => {
            new SeisanCheckIndexUpdateButtonEnabler(element, parentElement);
        });
    }

    private tableElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(tableElement: HTMLElement, parentElement: HTMLElement) {
        this.tableElement = tableElement;
        this.parentElement = parentElement;

        this.tableElement.querySelectorAll("input").forEach(element => {
            element.addEventListener("change", event => this.handleChange(event));
        });
    }

    private async handleChange(event: Event) {
        let updateButton = ObjectUtils.require(this.tableElement.querySelector("button[data-update-index]"), HTMLButtonElement);
        updateButton.classList.remove("disabled");
    }
}
