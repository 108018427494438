import ClearForm from "../action/ClearForm";
import SearchYubinBango from "../action/SearchYubinBango";
import KokyakuMenu from "./KokyakuMenu";

const bootstrap = require('bootstrap');

export default class KokyakuIndex {

    public static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=KokyakuIndex]").forEach((element) => {
            new KokyakuIndex(element);
        });
    }

    private kokyakuIndexElement: HTMLElement;                                     // 基準要素

    private constructor(kokyakuIndexElement: HTMLElement) {
        this.kokyakuIndexElement = kokyakuIndexElement;

        KokyakuMenu.initComponent(this.kokyakuIndexElement);
        ClearForm.initComponent(this.kokyakuIndexElement);
    }
}
