import * as objutils from "../lib/object-utils";

class YoyakuMenu {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=YoyakuMenu]").forEach((element) => {
            new YoyakuMenu(element, parentElement);
        });
    }

    private yoyakuMenuElement: HTMLElement;                                     // 基準要素
    private parentElement: HTMLElement;

    private constructor(yoyakuMenuElement: HTMLElement, parentElement: HTMLElement) {
        this.yoyakuMenuElement = yoyakuMenuElement;
        this.parentElement = parentElement;

        this.yoyakuMenuElement.addEventListener('show.bs.modal', event => this.handleShowBsModal(event));
    }

    private handleShowBsModal(event: any) {
        let relatedTarget = objutils.require<HTMLButtonElement>(event.relatedTarget);
        let bodyUrl = objutils.require<string>(relatedTarget.dataset["bodyUrl"]);
        let modalBody = objutils.require<HTMLElement>(this.yoyakuMenuElement.querySelector(".modal-body"));

        fetch(bodyUrl)
        .then((response) => response.text())
        .then((text) => {
            modalBody.innerHTML = text;
        });
    }

}

export default YoyakuMenu;