import ConfirmSubmitButton from "../action/ConfirmSubmitButton";
import ReloadingModalDialog from "../action/ReloadingModalDialog";

class ChumonSheetIndex {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=ChumonSheetIndex]").forEach((element) => {
            new ChumonSheetIndex(element);
        });
    }

    private chumonSheetIndexElement: HTMLElement;

    private constructor(chumonSheetIndexElement: HTMLElement) {
        this.chumonSheetIndexElement = chumonSheetIndexElement;

        ConfirmSubmitButton.initComponent(this.chumonSheetIndexElement);
    }
}

export default ChumonSheetIndex;
