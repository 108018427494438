import ObjectUtils from "../lib/ObjectUtils";
import ClearSearch from "./ClearSearch";

class ChangeChumonKubun {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLSelectElement>("[data-action=ChangeChumonKubun]").forEach((element) => {
            new ChangeChumonKubun(element, parentElement);
        });
    }

    private changeChumonKubunElement: HTMLSelectElement;
    private parentElement: HTMLElement;

    private constructor(changeChumonKubunElement: HTMLSelectElement, parentElement: HTMLElement) {
        this.changeChumonKubunElement = changeChumonKubunElement;
        this.parentElement = parentElement;

        this.changeChumonKubunElement.addEventListener("change", event => this.handleChange(event));
    }

    handleChange(event: Event): any {
        if (event.currentTarget instanceof HTMLSelectElement) {

            const selectedIndex = event.currentTarget.selectedIndex;
            const selectedOptionValue = ObjectUtils.require(this.changeChumonKubunElement[selectedIndex], HTMLOptionElement).value;

            // form をクリアする
            const clearButtonElement = ObjectUtils.require(this.parentElement.querySelector('#chumonSearchFormClear'), HTMLButtonElement);
            clearButtonElement.click();

            this.parentElement.querySelectorAll<HTMLElement>("[data-change-chumon-kubun]").forEach((element) => {
                this.changeClass(element, selectedOptionValue);
            });

            // 「検索結果」の form 属性を変更
            const formElementId = "chumonSearchForm" + selectedOptionValue;
            this.changeChumonKubunElement.setAttribute('form', formElementId);

            // 検索ボタンの form 属性値を変更
            const searchButtonElement = ObjectUtils.require(this.parentElement.querySelector('#chumonSearchFormSearchButton'), HTMLButtonElement);
            searchButtonElement.setAttribute('form', formElementId);

        }
    }

    private changeClass(element: HTMLElement, chumonKubun: string) {

        if (ObjectUtils.require(element, HTMLElement).dataset["changeChumonKubun"] === chumonKubun) {
            element.classList.remove('d-none');
        } else {
            element.classList.add('d-none');
        }

    }

}

export default ChangeChumonKubun;