import ChangeSagyoKomoku from "../action/ChangeSagyoKomoku";
import ChangeSeisanduki from "../action/ChangeSeisanduki";
import ConfirmSubmitButton from "../action/ConfirmSubmitButton";

class KokyakuJisshiEdit {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=KokyakuJisshiEdit]").forEach((element) => {
            new KokyakuJisshiEdit(element);
        });
    }

    private kokyakuJisshiEditElement: HTMLElement;

    private constructor(kokyakuJisshiEditElement: HTMLElement) {
        this.kokyakuJisshiEditElement = kokyakuJisshiEditElement;

        ChangeSagyoKomoku.initComponent(this.kokyakuJisshiEditElement);
        ChangeSeisanduki.initComponent(this.kokyakuJisshiEditElement);
        ConfirmSubmitButton.initComponent(this.kokyakuJisshiEditElement);

    }
}

export default KokyakuJisshiEdit;

window.addEventListener('DOMContentLoaded', (event) => {
    KokyakuJisshiEdit.initComponent();
});