import ConfirmSubmitButton from "../action/ConfirmSubmitButton";
import ChangeMainEmailType from "../action/ChangeMainEmailType";
import ChangeMainTelType from "../action/ChangeMainTelType";
import SearchYubinBango from "../action/SearchYubinBango";
import FormatTelKeitai from "../action/FormatTelKeitai";

export default class KokyakuEdit {

    public static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=KokyakuEdit]").forEach((element) => {
            new KokyakuEdit(element);
        });
    }

    private kokyakuEditElement: HTMLElement;

    private constructor(kokyakuEditElement: HTMLElement) {
        this.kokyakuEditElement = kokyakuEditElement;

        ChangeMainTelType.initComponent(this.kokyakuEditElement);
        ChangeMainEmailType.initComponent(this.kokyakuEditElement);
        SearchYubinBango.initComponent(this.kokyakuEditElement);
        ConfirmSubmitButton.initComponent(this.kokyakuEditElement);
        FormatTelKeitai.initComponent(this.kokyakuEditElement);
    }
}
