class OrdermadeSeihinSeisakuNissu {
    static initComponent(parentElement: HTMLElement) {

        parentElement.querySelectorAll<HTMLInputElement>("[data-action=OrdermadeSeihinSeisakuNissu]").forEach((element) => {
            new OrdermadeSeihinSeisakuNissu(element, parentElement);
        });
    }

    private ordermadeSeihinSeisakuNissuElement: HTMLInputElement;

    private parentElement: HTMLElement;

    private constructor(ordermadeSeihinSeisakuNissuElement: HTMLInputElement, parentElement: HTMLElement) {
        this.ordermadeSeihinSeisakuNissuElement = ordermadeSeihinSeisakuNissuElement;
        this.parentElement = parentElement;

        this.ordermadeSeihinSeisakuNissuElement.addEventListener('change', event => this.handleChange(event));
    }

    private handleChange(event: Event) {



    }
}

export default OrdermadeSeihinSeisakuNissu;