import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";

export default class ClearForm {
    public static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=ClearForm]").forEach((element) => {
            new ClearForm(element, parentElement);
        });
    }

    private clearFormElement: HTMLInputElement;
    private parentElement: HTMLElement;

    private constructor(clearFormElement: HTMLInputElement, parentElement: HTMLElement) {
        this.clearFormElement = clearFormElement;
        this.parentElement = parentElement;

        this.clearFormElement.addEventListener('click', event => this.handleClick(event));
    }

    private handleClick(event: Event) {

        let form = ObjectUtils.require(this.clearFormElement.form, HTMLFormElement);

        form.querySelectorAll<HTMLElement>("[data-clear-value]").forEach((element) => {
            let value = StringUtils.require(element.dataset["clearValue"]);

            if (element instanceof HTMLInputElement) {
                element.value = value;
            } else if (element instanceof HTMLSelectElement) {
                element.value = value;
            }
        });
    }
}