import ChangeHikiwatashiYotei from "../action/ChangeHikiwatashiYotei";

class ShuriOrderNew {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=ShuriOrderNew]").forEach((element) => {
            new ShuriOrderNew(element);
        });
    }

    private shuriOrderNewElement: HTMLElement;

    private constructor(shuriOrderNewElement: HTMLElement) {
        this.shuriOrderNewElement = shuriOrderNewElement;

        ChangeHikiwatashiYotei.initComponent(this.shuriOrderNewElement);
    }
}

export default ShuriOrderNew;