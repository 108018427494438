import ConfirmSubmitButton from "../action/ConfirmSubmitButton";
import ReloadingModalDialog from "../action/ReloadingModalDialog";
import FuzokuhinOrderFuzokuhinKosu from "./FuzokuhinOrderFuzokuhinKosu";

class FuzokuhinOrderFuzokuhinModal {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=FuzokuhinOrderFuzokuhinModal]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
            new FuzokuhinOrderFuzokuhinModal(element, parentElement);
        });
    }

    private fuzokuhinOrderFuzokuhinModalElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(fuzokuhinOrderFuzokuhinModalElement: HTMLElement, parentElement: HTMLElement) {
        this.fuzokuhinOrderFuzokuhinModalElement = fuzokuhinOrderFuzokuhinModalElement;
        this.parentElement = parentElement;

        this.fuzokuhinOrderFuzokuhinModalElement.addEventListener('ReloadingModalDialog:reloaded', event => this.handleReloaded(event));
    }

    private handleReloaded(event: Event) {
        FuzokuhinOrderFuzokuhinKosu.initComponent(this.fuzokuhinOrderFuzokuhinModalElement);
    }
}

export default FuzokuhinOrderFuzokuhinModal;
