import ObjectUtils from "../lib/ObjectUtils";

class ClearSearch {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLSelectElement>("[data-action=ClearSearch]").forEach((element) => {
            new ClearSearch(element, parentElement);
        });
    }

    private searchClearElement: HTMLSelectElement;
    private parentElement: HTMLElement;

    private constructor(searchClearElement: HTMLSelectElement, parentElement: HTMLElement) {
        this.searchClearElement = searchClearElement;
        this.parentElement = parentElement;

        this.searchClearElement.addEventListener("click", event => this.handleChange(event));
    }

    handleChange(event: Event) {
        this.parentElement.querySelectorAll("[data-clear-search=clear]").forEach((element) => {

            if (element instanceof HTMLSelectElement) {
                ObjectUtils.require(element, HTMLSelectElement).value = '';
            } else if (element instanceof HTMLInputElement) {
                ObjectUtils.require(element, HTMLInputElement).value = '';
            }

        });
    }

}

export default ClearSearch;