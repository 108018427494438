import ReloadingModalDialog from "../action/ReloadingModalDialog";
import ChangeSeisanduki from "../action/ChangeSeisanduki";
import ChangeSagyoKomoku from "../action/ChangeSagyoKomoku";

class ChumonJisshEditModal {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=ChumonJisshEditModal]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
            new ChumonJisshEditModal(element, parentElement);
        });
    }

    private chumonJisshEditModalElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(chumonJisshEditModalElement: HTMLElement, parentElement: HTMLElement) {
        this.chumonJisshEditModalElement = chumonJisshEditModalElement;
        this.parentElement = parentElement;

        this.chumonJisshEditModalElement.addEventListener('ReloadingModalDialog:reloaded', event => this.handleReloaded(event));
    }

    private handleReloaded(event: Event) {
        ChangeSeisanduki.initComponent(this.chumonJisshEditModalElement);
        ChangeSagyoKomoku.initComponent(this.chumonJisshEditModalElement);
    }
}

export default ChumonJisshEditModal;
