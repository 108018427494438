export default class DateUtils {

    static now(): Date {
        return new Date();
    }

    /**
     * Date インスタンスを生成する。
     *
     * new Date(y, m, d) はタイムゾーンが GMT になってしまうので使用してはいけない。
     */
    static newInstance(year: number, monthIndex: number, date?: number | undefined): Date {

        let yearStr = year.toString();
        let monthStr = (monthIndex + 1).toString().padStart(2, '0');
        let dayStr = date ? date.toString().padStart(2, '0') : "01";

        return new Date(yearStr + "-" + monthStr + "-" + dayStr);
    }

    static parse(s: string): Error|Date {

        let d = new Date(s);
        if (this.isInvalidDate(d)) {
            return new Error();
        }

        // d.setMinutes(d.getTimezoneOffset()); 精算月の変換のところでtimezoneがずれたのでコメントアウト
        return d;
    }

    static isInvalidDate(d: Date): boolean {
        return Number.isNaN(d.getTime());
    }

    static toISODateString(d: Date): string {
        return d.toISOString().substring(0, 10);
    }

    static toISOMonthString(d: Date): string {
        return d.toISOString().substring(0, 7);
    }

    static toDateTimeString(d: Date): string {
        let date = this.toISODateString(d);
        let time = d.toTimeString().substring(0,5);
        let resultDatetime = date + " " + time;

        return resultDatetime;
    }
}