import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";
import ConfirmDialog from "../action/ConfirmDialog";

export default class SeisanCheckIndexUpdateButton {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action~=SeisanCheckIndexUpdateButton]").forEach(element => {
            new SeisanCheckIndexUpdateButton(element, parentElement);
        });
    }

    private seisanCheckIndexUpdateButtonElement: HTMLElement;
    private parentElement: HTMLElement;
    private pending: boolean = true;

    private constructor(seisanCheckIndexUpdateButtonElement: HTMLElement, parentElement: HTMLElement) {
        this.seisanCheckIndexUpdateButtonElement = seisanCheckIndexUpdateButtonElement;
        this.parentElement = parentElement;

        this.seisanCheckIndexUpdateButtonElement.addEventListener('click', event => this.handleChange(event))
    }

    private handleChange(event: Event) {
        if (this.pending) {
            event.preventDefault();

            let message = StringUtils.require(this.seisanCheckIndexUpdateButtonElement.dataset["message"]);
            ConfirmDialog.getInstance().show(message, event => this.handleComfirmed(event), event => this.handleCancel(event));
        }

        this.pending = true;
    }

    private async handleComfirmed(event: Event) {
        let dataUpdateIndex = StringUtils.require(this.seisanCheckIndexUpdateButtonElement.dataset["updateIndex"]);

        let tableElement = ObjectUtils.require(this.parentElement.querySelector("table[data-update-index='" + dataUpdateIndex + "']"), HTMLElement);

        let data: any = [];

        tableElement.querySelectorAll<HTMLElement>("tr[data-jisshi-tesuryo-id]").forEach(trElement => {
            let jisshiTesuryoId = StringUtils.require(trElement.dataset["jisshiTesuryoId"]);
            let zeikomiKingaku = ObjectUtils.require(trElement.querySelector("[name=zeikomiKingaku]"), HTMLInputElement).value;
            let seisanduki = ObjectUtils.require(trElement.querySelector("[name=seisanduki]"), HTMLInputElement).value;
            let shonin = ObjectUtils.require(trElement.querySelector("[name=shonin]"), HTMLInputElement).checked;
            let seisanbi = ObjectUtils.require(trElement.querySelector("[name=seisanbi]"), HTMLInputElement).value;

            data.push({
                'jisshiTesuryoId': jisshiTesuryoId,
                'zeikomiKingaku': zeikomiKingaku,
                'seisanduki': seisanduki,
                'shonin': shonin,
                'seisanbi': seisanbi,
            })
        });

        let postUrl = this.seisanCheckIndexUpdateButtonElement.dataset["postUrl"];

        let _csrf = ObjectUtils.require(document.querySelector("meta[name=_csrf]"), HTMLMetaElement);
        let _csrf_header = ObjectUtils.require(document.querySelector("meta[name=_csrf_header]"), HTMLMetaElement);

        let response = await fetch(postUrl, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json', // JSON形式のデータのヘッダー
                'X-CSRF-TOKEN': _csrf.content,
            },
            body: JSON.stringify(data)
        });
        let restponeJson = await response.json();

        ConfirmDialog.getInstance().hide();
        this.seisanCheckIndexUpdateButtonElement.classList.add("disabled");

        console.log(restponeJson);
    }

    private handleCancel(event: Event) {
        this.pending = true;
    }
}