import ClickTrigger from "../action/ClickTrigger";
import ReloadingModalDialog from "../action/ReloadingModalDialog";
import ObjectUtils from "../lib/ObjectUtils";
import ChumonRyokinKeisan from "./ChumonRyokinKeisan";

/**
 * 注文料金基準日を変更したとき注文料金一覧をリロードする。
 */
export default class ChumonRyokinKijunbi {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=ChumonRyokinKijunbi]").forEach((element) => {
            new ChumonRyokinKijunbi(element, parentElement);
        });
    }

    private chumonRyokinKijunbiElement: HTMLInputElement;
    private parentElement: HTMLElement;

    private constructor(chumonRyokinKijunbiElement: HTMLInputElement, parentElement: HTMLElement) {
        this.chumonRyokinKijunbiElement = chumonRyokinKijunbiElement;
        this.parentElement = parentElement;

        this.chumonRyokinKijunbiElement.addEventListener('change', event => this.handleChange(event));
    }

    private async handleChange(event: Event) {
        ChumonRyokinKijunbi.reload(this.parentElement);
    }

    public static async reload(parentElement: HTMLElement) {

        // 選択済みの料金を再選択するためにチェック済みの料金を取得する。
        let checkedElements = parentElement.querySelectorAll<HTMLElement>("[data-action=ChumonRyokinKeisan]:checked");

        // 選択済みのキャンペーンを再選択するため選択済みの注文IDを取得する。
        let campaignId;
        let campaignIdElement = parentElement.querySelector<HTMLInputElement>("#campaignId");
        if (campaignIdElement) {
            campaignId = campaignIdElement.value;
        } else {
            campaignId = "";
        }

        let hontai;
        let hontaiOrNull = parentElement.querySelector<HTMLInputElement>("input[type=radio][data-chumon-hinmoku^=HONTAI_]:checked");
        if (hontaiOrNull) {
            hontai = hontaiOrNull.value;
        } else {
            hontai = "";
        }

        let kamishitsu;
        let kamishitsuOrNull = parentElement.querySelector<HTMLInputElement>("input[type=radio][data-chumon-hinmoku^=KAMISHITSU_]:checked");
        if (kamishitsuOrNull) {
            kamishitsu = kamishitsuOrNull.value;
        } else {
            kamishitsu = "";
        }

        let pack;
        let packOrNull = parentElement.querySelector<HTMLInputElement>("input[type=radio][data-chumon-hinmoku^=PACK_]:checked");
        if (packOrNull) {
            pack = packOrNull.value;
        } else {
            pack = "";
        }

        let chumonRyokinKijunbiElement = ObjectUtils.require(parentElement.querySelector("[data-action=ChumonRyokinKijunbi]"), HTMLInputElement);

        // 注文料金一覧のパラメータ。基準日を渡す。
        let params = new URLSearchParams({
            hontaiChumonRyokinId: hontai,
            kamishitsuChumonRyokinId: kamishitsu,
            packChumonRyokinId: pack,
            chumonRyokinKijunbi: chumonRyokinKijunbiElement.value
        }).toString();

        let url = ObjectUtils.requireString(chumonRyokinKijunbiElement.dataset["chumonRyokinIchiranUrl"]);

        // 注文料金一覧をリクエストする。
        let response = await fetch(url + "?" + params);

        // 置き換え。
        let chumonRyokinIchiranElement = ObjectUtils.require<HTMLElement>(parentElement.querySelector("#chumonRyokinIchiran"), HTMLElement);
        chumonRyokinIchiranElement.innerHTML = await response.text();

        // 料金を再選択
        checkedElements.forEach(checkedElement => {
            let selectors = "[data-action=ChumonRyokinKeisan][data-chumon-hinmoku=" + checkedElement.dataset['chumonHinmoku'] + "]";
            let element = parentElement.querySelector<HTMLInputElement>(selectors);
            if (element != null) {
                element.checked = true;
            }
        });

        // キャンペーンを再選択
        campaignIdElement = parentElement.querySelector<HTMLInputElement>("#campaignId");
        if (campaignIdElement) {
            campaignIdElement.value = campaignId;
        }

        // コンポーネントの初期化
        ClickTrigger.initComponent(chumonRyokinIchiranElement);
        ChumonRyokinKeisan.initComponent(chumonRyokinIchiranElement, parentElement);
    }
}
