import StringUtils from "../lib/StringUtils";

export default class ClientCheckHighlightText {

    public static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=highlight-text]").forEach((element) => {
            new ClientCheckHighlightText(parentElement);
        });
    }

    private clientCheckHighlightText: HTMLElement;                                     // 基準要素

    private constructor(clientCheckHighlightText: HTMLElement) {
        this.clientCheckHighlightText = clientCheckHighlightText;

        this.clientCheckHighlightText.querySelectorAll<HTMLElement>("[data-highlight-text]").forEach((element) => {
            let items = element.dataset["highlightText"];
            let itemsList = StringUtils.require(items).split(",");
            let result = element.innerHTML;

            itemsList.forEach((item) => {
                result = result.replace(new RegExp(item, "g"), "<span style=\"color: blue;\">" + item + "</span>");
            });

            element.innerHTML = result;
        });
    }
}
