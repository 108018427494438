import ChumonRyokinKeisan from "./ChumonRyokinKeisan";

class ChumonShiharaiHoho {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLSelectElement>("[data-action=ChumonShiharaiHoho]").forEach((element) => {
            new ChumonShiharaiHoho(element, parentElement);
        });
    }

    private chumonShiharaiHohoElement: HTMLSelectElement;
    private parentElement: HTMLElement;

    private constructor(chumonShiharaiHohoElement: HTMLSelectElement, parentElement: HTMLElement) {
        this.chumonShiharaiHohoElement = chumonShiharaiHohoElement;
        this.parentElement = parentElement;

        this.chumonShiharaiHohoElement.addEventListener('change', event => this.handleChange(event));
    }

    private handleChange(event: Event) {
        let checked = this.chumonShiharaiHohoElement.value == "IKKATSU";
        this.parentElement.querySelectorAll<HTMLInputElement>("input[type=checkbox][data-chumon-hinmoku=GENKIN_IKKATU_WARIBIKI]").forEach(e => e.checked = checked);
        ChumonRyokinKeisan.keisan(this.parentElement);
    }
}

export default ChumonShiharaiHoho;
