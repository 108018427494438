import ConfirmSubmitButton from "../action/ConfirmSubmitButton";
import ReloadingModalDialog from "../action/ReloadingModalDialog";

class KokyakuImageIndex {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=KokyakuImageIndex]").forEach((element) => {
            new KokyakuImageIndex(element);
        });
    }

    private kokyakuImageIndexElement: HTMLElement;

    private constructor(kokyakuImageIndexElement: HTMLElement) {
        this.kokyakuImageIndexElement = kokyakuImageIndexElement;

        ConfirmSubmitButton.initComponent(this.kokyakuImageIndexElement);
    }
}

export default KokyakuImageIndex;
