import ChangeSagyoKomoku from "../action/ChangeSagyoKomoku";
import ChangeSeisanduki from "../action/ChangeSeisanduki";
import ReloadingModalDialog from "../action/ReloadingModalDialog";

class ShuriOrderJisshiNewModal {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=ShuriOrderJisshiNewModal]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
            new ShuriOrderJisshiNewModal(element, parentElement);
        });
    }

    private shuriOrderJisshNewModalElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(shuriOrderJisshNewModalElement: HTMLElement, parentElement: HTMLElement) {
        this.shuriOrderJisshNewModalElement = shuriOrderJisshNewModalElement;
        this.parentElement = parentElement;

        this.shuriOrderJisshNewModalElement.addEventListener('ReloadingModalDialog:reloaded', event => this.handleReloaded(event));
    }

    private handleReloaded(event: Event) {
        ChangeSeisanduki.initComponent(this.shuriOrderJisshNewModalElement);
        ChangeSagyoKomoku.initComponent(this.shuriOrderJisshNewModalElement);
    }
}

export default ShuriOrderJisshiNewModal;
