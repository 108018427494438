import ChangeSagyoKomoku from "../action/ChangeSagyoKomoku";
import ChangeSeisanduki from "../action/ChangeSeisanduki";
import ClickTrigger from "../action/ClickTrigger";
import ChangeJisshiHiduke from "../action/ChangeJisshiHiduke";
import ChumonRyokinKeisan from "./ChumonRyokinKeisan";
import ChumonRyokinKijunbi from "./ChumonRyokinKijunbi";
import ChumonShiharaiHoho from "./ChumonShiharaiHoho";

class ChumonNew {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=ChumonNew]").forEach((element) => {
            new ChumonNew(element);
        });
    }

    private chumonNewElement: HTMLElement;

    private constructor(chumonNewElement: HTMLElement) {
        this.chumonNewElement = chumonNewElement;

        ChumonRyokinKijunbi.initComponent(this.chumonNewElement);
        ClickTrigger.initComponent(this.chumonNewElement);
        ChumonRyokinKeisan.initComponent(this.chumonNewElement);
        ChangeSeisanduki.initComponent(this.chumonNewElement);
        ChangeSagyoKomoku.initComponent(this.chumonNewElement);
        ChumonShiharaiHoho.initComponent(this.chumonNewElement);
        ChangeJisshiHiduke.initComponent(this.chumonNewElement);
    }
}

export default ChumonNew;
