import * as objutils from "../lib/object-utils";
import ConfirmDialog from "./ConfirmDialog";

class ConfirmSubmitButton {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=ConfirmSubmitButton]").forEach((element) => {
            new ConfirmSubmitButton(element, parentElement);
        });
    }

    private confirmSubmitButtonElement: HTMLInputElement;
    private parentElement: HTMLElement;
    private pending: boolean = true;

    private constructor(confirmSubmitButtonElement: HTMLInputElement, parentElement: HTMLElement) {
        this.confirmSubmitButtonElement = confirmSubmitButtonElement;
        this.parentElement = parentElement;

        this.confirmSubmitButtonElement.addEventListener("click", event => this.handleClick(event));
    }

    private handleClick(event: Event) {
        if (this.pending) {
            event.preventDefault();

            let message = objutils.require<string>(this.confirmSubmitButtonElement.dataset["message"]);
            ConfirmDialog.getInstance().show(message, event => this.handleComfirmed(event), event => this.handleCancel(event));
        }

        this.pending = true;
    }

    private handleComfirmed(event: Event) {
        this.pending = false;
        this.confirmSubmitButtonElement.click();
    }

    private handleCancel(event: Event) {
        this.pending = true;
    }
}

export default ConfirmSubmitButton;