import ChangeJisshiHiduke from "../action/ChangeJisshiHiduke";
import ChangeSagyoKomoku from "../action/ChangeSagyoKomoku";
import ChangeSeisanduki from "../action/ChangeSeisanduki";
import ClickTrigger from "../action/ClickTrigger";
import ChumonRyokinKeisan from "./ChumonRyokinKeisan";
import ChumonRyokinKijunbi from "./ChumonRyokinKijunbi";
import ChumonShiharaiHoho from "./ChumonShiharaiHoho";

class SemiOrderNew {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=SemiOrderNew]").forEach((element) => {
            new SemiOrderNew(element);
        });
    }

    private semiOrderNewElement: HTMLElement;

    private constructor(semiOrderNewElement: HTMLElement) {
        this.semiOrderNewElement = semiOrderNewElement;

        ChumonRyokinKijunbi.initComponent(this.semiOrderNewElement);
        ClickTrigger.initComponent(this.semiOrderNewElement);
        ChumonRyokinKeisan.initComponent(this.semiOrderNewElement);
        ChangeSeisanduki.initComponent(this.semiOrderNewElement);
        ChangeSagyoKomoku.initComponent(this.semiOrderNewElement);
        ChumonShiharaiHoho.initComponent(this.semiOrderNewElement);
        ChangeJisshiHiduke.initComponent(this.semiOrderNewElement);
    }
}

export default SemiOrderNew;
