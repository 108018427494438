import DenwaRenrakuOtaiKirokuModal from "./DenwaRenrakuOtaiKirokuModal";

class DenwaRenrakuIndex {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=DenwaRenrakuIndex]").forEach((element) => {
            new DenwaRenrakuIndex(element);
        });
    }

    private denwaRenrakuIndexElement: HTMLElement;

    private constructor(denwaRenrakuIndexElement: HTMLElement) {
        this.denwaRenrakuIndexElement = denwaRenrakuIndexElement;

        DenwaRenrakuOtaiKirokuModal.initComponent(this.denwaRenrakuIndexElement);
    }
}

export default DenwaRenrakuIndex;
