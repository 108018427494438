import ConfirmSubmitButton from "../action/ConfirmSubmitButton";
import ReloadingModalDialog from "../action/ReloadingModalDialog";
import SemiOrderFuzokuhinKosu from "./SemiOrderFuzokuhinKosu";

class SemiOrderFuzokuhinModal {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=SemiOrderFuzokuhinModal]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
            new SemiOrderFuzokuhinModal(element, parentElement);
        });
    }

    private semiOrderFuzokuhinModalElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(semiOrderFuzokuhinModalElement: HTMLElement, parentElement: HTMLElement) {
        this.semiOrderFuzokuhinModalElement = semiOrderFuzokuhinModalElement;
        this.parentElement = parentElement;

        this.semiOrderFuzokuhinModalElement.addEventListener('ReloadingModalDialog:reloaded', event => this.handleReloaded(event));
    }

    private handleReloaded(event: Event) {
        SemiOrderFuzokuhinKosu.initComponent(this.semiOrderFuzokuhinModalElement);
    }
}

export default SemiOrderFuzokuhinModal;
