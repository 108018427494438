import * as objutils from "../lib/object-utils";

const bootstrap = require('bootstrap');

class ConfirmDialog {

    private static instance: ConfirmDialog;

    static getInstance() {
        if (!this.instance) {
            let element = objutils.require<HTMLElement>(document.querySelector<HTMLElement>("#confirmDialog"));
            this.instance = new ConfirmDialog(element);
        }
        return this.instance;
    }

    confirmDialogElement: HTMLElement;                                     // 基準要素
    confirmedElement: HTMLElement;

    confirmedEventListener?: EventListenerOrEventListenerObject;
    cancelEventListener?: EventListenerOrEventListenerObject;

    private constructor(confirmDialogElement: HTMLElement) {
        this.confirmDialogElement = confirmDialogElement;
        this.confirmedElement = objutils.require<HTMLElement>(this.confirmDialogElement.querySelector<HTMLElement>("[data-action=confirmed]"));

        this.confirmDialogElement.addEventListener("hidden.bs.modal", event => this.handleHideBsModal(event));
    }

    private getModal(): any {
        let modal = bootstrap.Modal.getInstance(this.confirmDialogElement);
		if (modal == null) {
			modal = new bootstrap.Modal(this.confirmDialogElement);
        }
        return modal;
    }

    public show(message: string, confirmedEventListener: EventListenerOrEventListenerObject, cancelEventListener: EventListenerOrEventListenerObject) {
        this.confirmedEventListener = confirmedEventListener;
        this.cancelEventListener = cancelEventListener;

        let modalBody = objutils.require<HTMLElement>(this.confirmDialogElement.querySelector<HTMLElement>(".modal-body"));
        modalBody.textContent = message;

        this.confirmedElement.addEventListener('click', confirmedEventListener);
        this.confirmDialogElement.addEventListener("hide.bs.modal", cancelEventListener);

        this.getModal().show();
    }

    private handleHideBsModal(event: Event): void {
        if (this.confirmedEventListener) {
            this.confirmedElement.removeEventListener('click', this.confirmedEventListener);
        }
        if (this.cancelEventListener) {
            this.confirmDialogElement.removeEventListener('hide.bs.modal', this.cancelEventListener);
        }
    }

    public hide() {
        this.getModal().hide();
    }
}

export default ConfirmDialog;
