import ConfirmSubmitButton from "../action/ConfirmSubmitButton";
import ReloadingModalDialog from "../action/ReloadingModalDialog";
import ChumonFuzokuhinKosu from "./ChumonFuzokuhinKosu";

class ChumonFuzokuhinModal {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=ChumonFuzokuhinModal]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
            new ChumonFuzokuhinModal(element, parentElement);
        });
    }

    private chumonFuzokuhinModalElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(chumonFuzokuhinModalElement: HTMLElement, parentElement: HTMLElement) {
        this.chumonFuzokuhinModalElement = chumonFuzokuhinModalElement;
        this.parentElement = parentElement;

        this.chumonFuzokuhinModalElement.addEventListener('ReloadingModalDialog:reloaded', event => this.handleReloaded(event));
    }

    private handleReloaded(event: Event) {
        ChumonFuzokuhinKosu.initComponent(this.chumonFuzokuhinModalElement);
    }
}

export default ChumonFuzokuhinModal;
