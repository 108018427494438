import NumberUtils from "../lib/NumberUtils";
import ObjectUtils from "../lib/ObjectUtils";

/**
 * 送金額および相殺額を計算するコンポーネント
 */
class SokinSosaigakuKeisan {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLSelectElement>("[data-action=SokinSosaigakuKeisan]").forEach((element) => {
            new SokinSosaigakuKeisan(element, parentElement);
        });
    }

    private sokinSosaigakuKeisanElement: HTMLSelectElement;
    private parentElement: HTMLElement;

    private constructor(sokinSosaigakuKeisanElement: HTMLSelectElement, parentElement: HTMLElement) {
        this.sokinSosaigakuKeisanElement = sokinSosaigakuKeisanElement;
        this.parentElement = parentElement;

        this.sokinSosaigakuKeisanElement.addEventListener('change', event => this.handleChange(event));
    }

    private handleChange(event: Event) {

        // 送金額合計計算
        let sokingaku = 0;

        this.parentElement.querySelectorAll<HTMLSelectElement>("[value=SOKIN][data-action=SokinSosaigakuKeisan]:checked").forEach((element) => {
            sokingaku += NumberUtils.parse(element.dataset["uketorigaku"]);
        });

        let sokingakuGokeiElement = ObjectUtils.require(this.parentElement.querySelector("#sokingakuGokei"), HTMLInputElement);
        sokingakuGokeiElement.value = NumberUtils.toString(sokingaku);

        // 相殺額合計計算
        let sosaigaku = 0;

        this.parentElement.querySelectorAll<HTMLSelectElement>("[value=SOSAI][data-action=SokinSosaigakuKeisan]:checked").forEach((element) => {
            sosaigaku += NumberUtils.parse(element.dataset["uketorigaku"]);
        });

        let sosaigakuGokeiElement = ObjectUtils.require(this.parentElement.querySelector("#sosaigakuGokei"), HTMLInputElement);
        sosaigakuGokeiElement.value = NumberUtils.toString(sosaigaku);
    }
}

export default SokinSosaigakuKeisan;
