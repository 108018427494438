import ReloadingModalDialog from "../action/ReloadingModalDialog";
import KokyakuBiko from "./KokyakuBiko";

export default class KokyakuShow {

    public static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=KokyakuShow]").forEach((element) => {
            new KokyakuShow(element);
        });
    }

    private kokyakuShowElement: HTMLElement;

    private constructor(kokyakuShowElement: HTMLElement) {
        this.kokyakuShowElement = kokyakuShowElement;

        KokyakuBiko.initComponent(this.kokyakuShowElement);
        ReloadingModalDialog.initComponent(this.kokyakuShowElement);
    }
}
