import ReloadingModalDialog from "../action/ReloadingModalDialog";
import SemiOrderFuzokuhinModal from "./SemiOrderFuzokuhinModal";
import SemiOrderJisshiEditModal from "./SemiOrderJisshiEditModal";
import SemiOrderJisshiNewModal from "./SemiOrderJisshiNewModal";
import SemiOrderJisshiShowModal from "./SemiOrderJisshiShowModal";

class SemiOrderShow {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=SemiOrderShow]").forEach((element) => {
            new SemiOrderShow(element);
        });
    }

    private semiOrderShowElement: HTMLElement;

    private constructor(semiOrderShowElement: HTMLElement) {
        this.semiOrderShowElement = semiOrderShowElement;

        ReloadingModalDialog.initComponent(this.semiOrderShowElement);
        SemiOrderJisshiShowModal.initComponent(this.semiOrderShowElement);
        SemiOrderJisshiNewModal.initComponent(this.semiOrderShowElement);
        SemiOrderJisshiEditModal.initComponent(this.semiOrderShowElement);
        SemiOrderFuzokuhinModal.initComponent(this.semiOrderShowElement);
    }
}

export default SemiOrderShow;
