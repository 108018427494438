import NumberUtils from "../lib/NumberUtils";
import ObjectUtils from "../lib/ObjectUtils";

class OrdermadeSeihinIppanPlusRemi {
    static initComponent(parentElement: HTMLElement) {
      // ①OrdermadeSeihinNew から持ってきた parentElement から data-action=OrdermadeSeihinIppanPlusRemi をもつエレメントを探す。
      // 見つけるごとに constructor が動く
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=OrdermadeSeihinIppanPlusRemi]").forEach((element) => {
            new OrdermadeSeihinIppanPlusRemi(element, parentElement);
        });
    }

    private ordermadeSeihinIppanPlusRemiElement: HTMLInputElement;

    private parentElement: HTMLElement;

    // ordermadeSeihinIppanPlusRemiElement = data-action=OrdermadeSeihinIppanPlusRemi を持つエレメント
    // parentElement = data-action=OrdermadeSeihinNew を持つエレメント(オーダーメイド製品管理登録)
    private constructor(ordermadeSeihinIppanPlusRemiElement: HTMLInputElement, parentElement: HTMLElement) {
        this.ordermadeSeihinIppanPlusRemiElement = ordermadeSeihinIppanPlusRemiElement;
        this.parentElement = parentElement;

        // ordermadeSeihinIppanPlusRemiElement に変更があると handleChange が動く
        this.ordermadeSeihinIppanPlusRemiElement.addEventListener('change', event => this.handleChange(event));
    }

    // ordermadeSeihinIppanPlusRemiElement に変更があるたびに動く
    private handleChange(event: Event) {
        let ippanmoMainKojoShinkokuMoryoElement = ObjectUtils.require(this.parentElement.querySelector("#ippanmoMainKojoShinkokuMoryo"), HTMLInputElement);
        let ippanmoSecondKojoShinkokuMoryoElement = ObjectUtils.require(this.parentElement.querySelector("#ippanmoSecondKojoShinkokuMoryo"), HTMLInputElement);

        let remiHairMainKojoShinkokuMoryoElement = ObjectUtils.require(this.parentElement.querySelector("#remiHairMainKojoShinkokuMoryo"), HTMLInputElement);
        let remiHairMainKojoShinkokuLossMoryoElement = ObjectUtils.require(this.parentElement.querySelector("#remiHairMainKojoShinkokuLossMoryo"), HTMLInputElement);
        let remiHairSecondKojoShinkokuMoryoElement = ObjectUtils.require(this.parentElement.querySelector("#remiHairSecondKojoShinkokuMoryo"), HTMLInputElement);
        let remiHairSecondKojoShinkokuLossMoryoElement = ObjectUtils.require(this.parentElement.querySelector("#remiHairSecondKojoShinkokuLossMoryo"), HTMLInputElement);

        // 実質レミー毛量(メイン)の計算
        // HTMLInputElement型から文字列で取り出した remiHairMainKojoShinkokuMoryoElement, remiHairMainKojoShinkokuLossMoryoElement を数値に変え、計算したものを mainJisshitsuRemiMoryo に代入
        let mainJisshitsuRemiMoryo = NumberUtils.parse(remiHairMainKojoShinkokuMoryoElement.value) + NumberUtils.parse(remiHairMainKojoShinkokuLossMoryoElement.value);
        // remiHairMainJisshituRemiMoryoElement に remiHairMainJisshituRemiMoryo を代入
        let remiHairMainJisshituRemiMoryoElement = ObjectUtils.require(this.parentElement.querySelector("#remiHairMainJisshituRemiMoryo"), HTMLInputElement);
        // 計算結果の mainJisshitsuRemiMoryo を文字列に変更し、remiHairMainJisshituRemiMoryoElement.value に代入
        remiHairMainJisshituRemiMoryoElement.value = mainJisshitsuRemiMoryo.toString();

        // 実質レミー毛量(セカンド)の計算
        let secondJisshitsuRemiMoryo = NumberUtils.parse(remiHairSecondKojoShinkokuMoryoElement.value) + NumberUtils.parse(remiHairSecondKojoShinkokuLossMoryoElement.value);
        let remiHairSecondJisshituRemiMoryoElement = ObjectUtils.require(this.parentElement.querySelector("#remiHairSecondJisshituRemiMoryo"), HTMLInputElement);
        remiHairSecondJisshituRemiMoryoElement.value = secondJisshitsuRemiMoryo.toString();

        // 工場申告一般＋レミー総量の計算
        let ippanPlusRemi = NumberUtils.parse(ippanmoMainKojoShinkokuMoryoElement.value) + NumberUtils.parse(ippanmoSecondKojoShinkokuMoryoElement.value) + mainJisshitsuRemiMoryo + secondJisshitsuRemiMoryo;
        let ippanPlusRemiElement = ObjectUtils.require(this.parentElement.querySelector("#kojoShinkokuIppanPlusRemiSoryo"), HTMLInputElement);
        ippanPlusRemiElement.value = ippanPlusRemi.toString();
    }
}

export default OrdermadeSeihinIppanPlusRemi;
