import ChangeHikiwatashiYotei from "../action/ChangeHikiwatashiYotei";
import ConfirmSubmitButton from "../action/ConfirmSubmitButton";

class ShuriOrderEdit {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=ShuriOrderEdit]").forEach((element) => {
            new ShuriOrderEdit(element);
        });
    }

    private shuriOrderEditElement: HTMLElement;

    private constructor(shuriOrderEditElement: HTMLElement) {
        this.shuriOrderEditElement = shuriOrderEditElement;

        ChangeHikiwatashiYotei.initComponent(this.shuriOrderEditElement);
        ConfirmSubmitButton.initComponent(this.shuriOrderEditElement);
    }

}

export default ShuriOrderEdit;