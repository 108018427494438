import NumberUtils from "../lib/NumberUtils";
import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";

class ChumonRyokinKeisan {
    static initComponent(parentElement: HTMLElement, overrideParentElement?: HTMLElement) {

        if (overrideParentElement != undefined) {
            parentElement.querySelectorAll<HTMLInputElement>("[data-action=ChumonRyokinKeisan]").forEach(element => {
                new ChumonRyokinKeisan(element, overrideParentElement);
            });

            this.keisan(overrideParentElement);

        } else {
            parentElement.querySelectorAll<HTMLInputElement>("[data-action=ChumonRyokinKeisan]").forEach(element => {
                new ChumonRyokinKeisan(element, parentElement);
            });

            this.keisan(parentElement);
        }
    }

    private chumonRyokinKeisanElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(chumonRyokinKeisanElement: HTMLElement, parentElement: HTMLElement) {
        this.chumonRyokinKeisanElement = chumonRyokinKeisanElement;
        this.parentElement = parentElement;

        this.chumonRyokinKeisanElement.addEventListener("change", event => this.handleClick(event));
    }

    private handleClick(event: Event) {
        ChumonRyokinKeisan.keisan(this.parentElement);
    }

    public static keisan(parentElement: HTMLElement) {
        let ryokin = 0;
        let waribiki = 0;

        // 集計
        parentElement.querySelectorAll<HTMLElement>("input[type=checkbox][data-chumon-ryokin]:checked").forEach((element) => {
            ryokin += NumberUtils.parse(element.dataset["chumonRyokin"]);
        });

        parentElement.querySelectorAll<HTMLElement>("input[type=radio][data-chumon-ryokin]:checked").forEach((element) => {
            ryokin += NumberUtils.parse(element.dataset["chumonRyokin"]);
        });

        parentElement.querySelectorAll<HTMLElement>("input[type=checkbox][data-chumon-waribiki]:checked").forEach((element) => {
            waribiki += NumberUtils.parse(element.dataset["chumonWaribiki"]);
        });

        parentElement.querySelectorAll<HTMLInputElement>("input[type=text][data-chumon-ryokin]").forEach((element) => {
            ryokin += NumberUtils.parse(element.value);
        });

        parentElement.querySelectorAll<HTMLSelectElement>("select[data-action=ChumonRyokinKeisan]").forEach((element) => {
            if (0 <= element.selectedIndex) {
                let chumonRyokin = NumberUtils.parse(element.options[element.selectedIndex].dataset["chumonRyokin"]);
                ryokin += chumonRyokin

                let ryokinFieldSelector = StringUtils.require(element.dataset["ryokinField"]);
                let ryokinFieldElement = ObjectUtils.require(parentElement.querySelector(ryokinFieldSelector), HTMLElement);
                ryokinFieldElement.innerText = NumberUtils.currency(chumonRyokin);
            }
        });

        // 設定
        let chumonWaribikiGokeiElement = ObjectUtils.require(parentElement.querySelector("#chumonWaribikiGokei"), HTMLElement);
        chumonWaribikiGokeiElement.innerHTML = NumberUtils.currency(waribiki);

        let chumonRyokinGokeiElement = ObjectUtils.require(parentElement.querySelector("#chumonRyokinGokei"), HTMLElement);
        chumonRyokinGokeiElement.innerHTML = NumberUtils.currency(ryokin + waribiki);
    }
}

export default ChumonRyokinKeisan;
