import ObjectUtils from "../lib/ObjectUtils";
import ConfirmSubmitButton from "./ConfirmSubmitButton";
import ReloadingModalSubmitButton from "./ReloadingModalSubmitButton";

/**
 * bootstrap の Modal の表示と同時に指定した URL からコンテンツを取得して .modal-content を置き換える。
 *
 * 指定する属性（Modal 側）
 * data-action="ReloadingModalDialog"     アクションコンポーネント
 *
 * 指定する属性（ボタン側）
 * data-bs-toggle="modal"                 bootstrap の属性。
 * data-bs-target="#exampleModal"         bootstrap の属性。Modal へのセレクタ。
 * data-modal-content-url="/examplePath"  .modal-content を置き換えるコンテンツの取得先 URL。
 *
 * ※コンテンツの置き換え後、ReloadingModalSubmitButton アクションコンポーネントを初期化する。
 */
class ReloadingModalDialog {

    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=ReloadingModalDialog]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
        });
    }

    private reloadingModalDialogElement: HTMLElement;
    private parentElement: HTMLElement;
    private clearElement!: HTMLElement;

    constructor(reloadingModalDialogElement: HTMLElement, parentElement: HTMLElement) {
        this.reloadingModalDialogElement = reloadingModalDialogElement;
        this.parentElement = parentElement;

        this.reloadingModalDialogElement.addEventListener('show.bs.modal', event => this.handleShowBsModal(event));
        this.reloadingModalDialogElement.addEventListener('hidden.bs.modal', event => this.handleHiddenBsModal(event));
    }

    /**
     * モーダルが表示されると data-modal-content-url に指定されたコンテンツを .modal-dialog 下にロードする。
     *
     * @param event Event オブジェクト※relatedTarget にアクセスするために方は any
     */
    private async handleShowBsModal(event: any) {
        let relatedTarget = ObjectUtils.require(event.relatedTarget, HTMLButtonElement);

        let modalContentUrl = relatedTarget.dataset["modalContentUrl"];
        if (modalContentUrl) {
            let modalDialogElement = ObjectUtils.require(this.reloadingModalDialogElement.querySelector(".modal-dialog"), HTMLElement);

            let response = await fetch(modalContentUrl);

            modalDialogElement.innerHTML = await response.text();

            this.clearElement = modalDialogElement;

            ConfirmSubmitButton.initComponent(this.reloadingModalDialogElement);
            ReloadingModalSubmitButton.initComponent(this.reloadingModalDialogElement);

            let event = new CustomEvent("ReloadingModalDialog:reloaded");
            this.reloadingModalDialogElement.dispatchEvent(event);
        }

        // ※非推奨
        let contentUrl = relatedTarget.dataset["contentUrl"];
        if (contentUrl) {
            console.warn("data-modal-content-url を使用して下さい。");
            let modalContent = ObjectUtils.require(this.reloadingModalDialogElement.querySelector(".modal-content"), HTMLElement);

            fetch(contentUrl)
            .then((response) => response.text())
            .then((text) => {
                modalContent.innerHTML = text;
                this.clearElement = modalContent;
            });
        }

        // ※非推奨
        let bodyUrl = relatedTarget.dataset["bodyUrl"];
        if (bodyUrl) {
            console.warn("data-modal-content-url を使用して下さい。");

            let modalBody = ObjectUtils.require(this.reloadingModalDialogElement.querySelector(".modal-body"), HTMLElement);

            fetch(bodyUrl)
            .then((response) => response.text())
            .then((text) => {
                modalBody.innerHTML = text;
                this.clearElement = modalBody;
            });
        }
    }

    /**
     * モーダルが非表示になるとロードしたコンテンツを消す。
     *
     * @param event Event オブジェクト
     */
    private async handleHiddenBsModal(event: Event) {
        this.clearElement.innerHTML = "";
    }
}

export default ReloadingModalDialog;