import ObjectUtils from "../lib/ObjectUtils";

class YoyakuShuruiKubun {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=YoyakuShuruiKubun]").forEach((element) => {
            new YoyakuShuruiKubun(element, parentElement);
        });
    }

    private yoyakuShuruiKubunElement: HTMLInputElement;                                     // 基準要素
    private parentElement: HTMLElement;                                                     // 親要素

    private constructor(yoyakuShuruiKubunElement: HTMLInputElement, parentElement: HTMLElement) {
        this.yoyakuShuruiKubunElement = yoyakuShuruiKubunElement;
        this.parentElement = parentElement;

        this.yoyakuShuruiKubunElement.addEventListener('change', event => this.handleClick(event));
    }

    private handleClick(event: Event): any {

        let yoyakuShurui = ObjectUtils.require(this.parentElement.querySelector("#yoyakuShurui"), HTMLSelectElement);
        yoyakuShurui.selectedIndex = -1;

        this.parentElement.querySelectorAll<HTMLOptionElement>("[data-yoyaku-shurui-kubun]").forEach((element) => {
            if (this.yoyakuShuruiKubunElement.value === element.dataset["yoyakuShuruiKubun"]) {
                element.classList.remove("d-none");
            } else {
                element.classList.add("d-none");
            }
        });
    }

}

export default YoyakuShuruiKubun;