import ChangeSeisanduki from "../action/ChangeSeisanduki";
import ClickTrigger from "../action/ClickTrigger";
import ChumonRyokinKeisan from "./ChumonRyokinKeisan";
import ChumonRyokinKijunbi from "./ChumonRyokinKijunbi";
import ChumonShiharaiHoho from "./ChumonShiharaiHoho";

class ChumonEdit {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=ChumonEdit]").forEach((element) => {
            new ChumonEdit(element);
        });
    }

    private chumonEditElement: HTMLElement;

    private constructor(chumonEditElement: HTMLElement) {
        this.chumonEditElement = chumonEditElement;

        ChumonRyokinKijunbi.initComponent(this.chumonEditElement);
        ClickTrigger.initComponent(this.chumonEditElement);
        ChumonRyokinKeisan.initComponent(this.chumonEditElement);
        ChangeSeisanduki.initComponent(this.chumonEditElement);
        ChumonShiharaiHoho.initComponent(this.chumonEditElement);
    }
}

export default ChumonEdit;
