class StringUtils {

    static isBlank(str: string | null | undefined): boolean {
        return str === null || str === undefined || str.trim().length === 0;
    }

    static isNotBlank(str: string | null | undefined): boolean {
        return !this.isBlank(str);
    }

    static require(obj: any): string {
        if (typeof(obj) !== "string") {
            throw Error();
        }

        return obj;
    }
}

export default StringUtils;
