export default class ChangeMainEmailType {

    public static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=ChangeMainEmailType]").forEach((element) => {
            new ChangeMainEmailType(element, parentElement);
        });
    }

    private changeMainEmailTypeElement: HTMLInputElement;                                     // 基準要素
    private parentElement: HTMLElement;

    private constructor(changeMainEmailTypeElement: HTMLInputElement, parentElement: HTMLElement) {
        this.changeMainEmailTypeElement = changeMainEmailTypeElement;
        this.parentElement = parentElement;

        this.changeMainEmailTypeElement.addEventListener("change", (ev) => this.handleChange(ev));
    }

    private handleChange(ev: Event) {
        let value = this.changeMainEmailTypeElement.value;

        this.parentElement.querySelectorAll<HTMLElement>("[data-main-email-type]").forEach((element) => {
            if (element.dataset["mainEmailType"] === value) {
                element.classList.remove("d-none");
            } else {
                element.classList.add("d-none");
            }
        });
    }
}
