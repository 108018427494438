import ChangeMainEmailType from "../action/ChangeMainEmailType";
import ChangeMainTelType from "../action/ChangeMainTelType";
import SearchYubinBango from "../action/SearchYubinBango";
import FormatTelKeitai from "../action/FormatTelKeitai";

export default class KokyakuNew {

    public static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=KokyakuNew]").forEach((element) => {
            new KokyakuNew(element);
        });
    }

    private kokyakuNewElement: HTMLElement;

    private constructor(kokyakuNewElement: HTMLElement) {
        this.kokyakuNewElement = kokyakuNewElement;

        ChangeMainTelType.initComponent(this.kokyakuNewElement);
        ChangeMainEmailType.initComponent(this.kokyakuNewElement);
        SearchYubinBango.initComponent(this.kokyakuNewElement);
        FormatTelKeitai.initComponent(this.kokyakuNewElement);
    }
}
