import NumberUtils from "../lib/NumberUtils";
import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";
import ChumonRyokinKijunbi from "./ChumonRyokinKijunbi";

class ChumonRyokinKeisan {

    static initComponent(parentElement: HTMLElement, overrideParentElement?: HTMLElement) {

        if (overrideParentElement != undefined) {

            parentElement.querySelectorAll<HTMLElement>("[data-action=ChumonRyokinKeisan]").forEach(element => {
                new ChumonRyokinKeisan(element, overrideParentElement);
            });
            // 料金を計算
            ChumonRyokinKeisan.keisan(overrideParentElement);

        } else {

            parentElement.querySelectorAll<HTMLElement>("[data-action=ChumonRyokinKeisan]").forEach(element => {
                new ChumonRyokinKeisan(element, parentElement);
            });

            // 料金を計算
            ChumonRyokinKeisan.keisan(parentElement);
        }
    }

    private chumonRyokinKeisanElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(chumonRyokinKeisanElement: HTMLElement, parentElement: HTMLElement) {
        this.chumonRyokinKeisanElement = chumonRyokinKeisanElement;
        this.parentElement = parentElement;

        if (this.chumonRyokinKeisanElement instanceof HTMLInputElement) {
            this.chumonRyokinKeisanElement.addEventListener("change", event => this.handleClick(event));
        } else if (this.chumonRyokinKeisanElement instanceof HTMLSelectElement) {
            this.chumonRyokinKeisanElement.addEventListener("change", event => this.handleSelectChange(event));
        }
    }

    private handleClick(event: Event) {

        if (!(this.chumonRyokinKeisanElement instanceof HTMLInputElement)) {
            return;
        }

        let chumonHinmoku = this.chumonRyokinKeisanElement.dataset["chumonHinmoku"];

        if (!chumonHinmoku) {
            ChumonRyokinKeisan.keisan(this.parentElement);
            return;
        }

        if (this.chumonRyokinKeisanElement.checked) {

            if (chumonHinmoku.indexOf("KAMISHITSU_") === 0) {
                this.uncheckPack();

            } else if (chumonHinmoku.indexOf("PACK_") === 0) {
                this.uncheckKamishitsu();
            }

            if (chumonHinmoku === "OPTION_FREESTYLE_AIR_PLUS_SHIYO") {
                this.uncheckRemyAngel();
                this.uncheckPack();

            } else if (chumonHinmoku === "OPTION_NATURAL_FRONT_SHIYO") {
                this.uncheckPackTsujomo15CmDouble();

            } else if (chumonHinmoku.indexOf("KAMISHITSU_REMY_ANGEL_") === 0) {
                this.uncheckOptionFreestyleAirPlusShiyo();

            } else if (chumonHinmoku === "PACK_TSUJOMO_15_CM_DOUBLE") {
                this.uncheckOptionFreestyleAirPlusShiyo();
                this.uncheckOptionNaturalFrontShiyo();

            } else if (chumonHinmoku.indexOf("PACK_REMY_ANGEL_") === 0) {
                this.uncheckOptionFreestyleAirPlusShiyo();
            }
        }

        if (chumonHinmoku.indexOf("HONTAI_") === 0 || chumonHinmoku.indexOf("KAMISHITSU_") === 0 || chumonHinmoku.indexOf("PACK_") === 0) {
            ChumonRyokinKijunbi.reload(this.parentElement);
        } else {
            ChumonRyokinKeisan.keisan(this.parentElement);
        }

        if (chumonHinmoku === "GENKIN_IKKATU_WARIBIKI") {
            let shiharaiHohoValue = this.chumonRyokinKeisanElement.checked ? "IKKATSU" : "";

            this.parentElement.querySelectorAll<HTMLSelectElement>("#shiharaiHoho").forEach((element) => {
                element.value = shiharaiHohoValue;
            });
        }
    }

    private uncheckKamishitsu() {
        this.parentElement.querySelectorAll<HTMLInputElement>("input[type=radio][data-chumon-hinmoku^=KAMISHITSU_]:checked").forEach(e => e.checked = false);
    }

    private uncheckPack() {
        this.parentElement.querySelectorAll<HTMLInputElement>("input[type=radio][data-chumon-hinmoku^=PACK_]:checked").forEach(e => e.checked = false);
    }

    private uncheckOptionFreestyleAirPlusShiyo() {
        this.parentElement.querySelectorAll<HTMLInputElement>("input[type=checkbox][data-chumon-hinmoku=OPTION_FREESTYLE_AIR_PLUS_SHIYO]:checked").forEach(e => e.checked = false);
    }

    private uncheckOptionNaturalFrontShiyo() {
        this.parentElement.querySelectorAll<HTMLInputElement>("input[type=checkbox][data-chumon-hinmoku=OPTION_NATURAL_FRONT_SHIYO]:checked").forEach(e => e.checked = false);
    }

    private uncheckPackTsujomo15CmDouble() {
        this.parentElement.querySelectorAll<HTMLInputElement>("input[type=radio][data-chumon-hinmoku=PACK_TSUJOMO_15_CM_DOUBLE]:checked").forEach(e => e.checked = false);
    }

    private uncheckRemyAngel() {
        this.parentElement.querySelectorAll<HTMLInputElement>("input[type=radio][data-chumon-hinmoku^=KAMISHITSU_REMY_]:checked").forEach(e => e.checked = false);
    }

    private handleSelectChange(event: Event) {
        if (!(this.chumonRyokinKeisanElement instanceof HTMLSelectElement)) {
            return;
        }

        ChumonRyokinKeisan.keisan(this.parentElement);
    }

    public static keisan(parentElement: HTMLElement) {
        let ryokin = 0;
        let waribiki = 0;

        // 集計
        parentElement.querySelectorAll<HTMLElement>("input[type=checkbox][data-chumon-ryokin]:checked").forEach((element) => {
            ryokin += NumberUtils.parse(element.dataset["chumonRyokin"]);
        });

        parentElement.querySelectorAll<HTMLElement>("input[type=radio][data-chumon-ryokin]:checked").forEach((element) => {
            ryokin += NumberUtils.parse(element.dataset["chumonRyokin"]);
        });

        parentElement.querySelectorAll<HTMLElement>("input[type=checkbox][data-chumon-waribiki]:checked").forEach((element) => {
            waribiki += NumberUtils.parse(element.dataset["chumonWaribiki"]);
        });

        parentElement.querySelectorAll<HTMLInputElement>("input[type=text][data-chumon-ryokin]").forEach((element) => {
            ryokin += NumberUtils.parse(element.value);
        });

        parentElement.querySelectorAll<HTMLSelectElement>("select[data-action=ChumonRyokinKeisan]").forEach((element) => {
            if (0 <= element.selectedIndex) {
                let chumonRyokin = NumberUtils.parse(element.options[element.selectedIndex].dataset["chumonRyokin"]);
                ryokin += chumonRyokin

                let ryokinFieldSelector = StringUtils.require(element.dataset["ryokinField"]);
                let ryokinFieldElement = ObjectUtils.require(parentElement.querySelector(ryokinFieldSelector), HTMLElement);
                ryokinFieldElement.innerText = NumberUtils.currency(chumonRyokin);
            }
        });

        // 設定
        let chumonWaribikiGokeiElement = ObjectUtils.require(parentElement.querySelector("#chumonWaribikiGokei"), HTMLElement);
        chumonWaribikiGokeiElement.innerHTML = NumberUtils.currency(waribiki);

        let chumonRyokinGokeiElement = ObjectUtils.require(parentElement.querySelector("#chumonRyokinGokei"), HTMLElement);
        chumonRyokinGokeiElement.innerHTML = NumberUtils.currency(ryokin + waribiki);
    }
}

export default ChumonRyokinKeisan;
