import ChangeMainEmailType from "../action/ChangeMainEmailType";
import ChangeMainTelType from "../action/ChangeMainTelType";
import FormatTelKeitai from "../action/FormatTelKeitai";

export default class ClientCheckEdit {

    public static initComponent() {
        document.querySelectorAll<HTMLElement>("#clientCheckEdit").forEach((element) => {
            new ClientCheckEdit(element);
        });
    }

    private clientCheckEditElement: HTMLElement;                                     // 基準要素

    private constructor(clientCheckEditElement: HTMLElement) {
        this.clientCheckEditElement = clientCheckEditElement;

        ChangeMainTelType.initComponent(this.clientCheckEditElement);
        ChangeMainEmailType.initComponent(this.clientCheckEditElement);
        FormatTelKeitai.initComponent(this.clientCheckEditElement);
    }
}
