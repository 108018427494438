import OrdermadeSeihinIppanPlusRemi from "./OrdermadeSeihinIppanPlusRemi";
import OrdermadeSeihinKamiSoryo from "./OrdermadeSeihinKamiSoryo";
import OrdermadeSeihinSeihinId from "./OrdermadeSeihinSeihinId";
import OrdermadeSeihinSeisakuNissu from "./OrdermadeSeihinSeisakuNissu";

class OrdermadeSeihinEdit {
    static initComponent() {
        // ②data-action=OrdermadeSeihinEditを探す。見つけるごとにconstructorが動く
        // elementはdata-action=OrdermadeSeihinEditを持っているタグ
        document.querySelectorAll<HTMLElement>("[data-action=OrdermadeSeihinEdit]").forEach((element) => {
            new OrdermadeSeihinEdit(element);
        });
    }

    private ordermadeSeihinEditElement: HTMLElement;

    // ③初期化を行い、OrdermadeSeihinKamiSoryo.initComponentが動く
    private constructor(ordermadeSeihinEditElement: HTMLElement) {
        this.ordermadeSeihinEditElement = ordermadeSeihinEditElement;

        OrdermadeSeihinKamiSoryo.initComponent(this.ordermadeSeihinEditElement);
        OrdermadeSeihinIppanPlusRemi.initComponent(this.ordermadeSeihinEditElement);
        OrdermadeSeihinSeisakuNissu.initComponent(this.ordermadeSeihinEditElement);
        OrdermadeSeihinSeihinId.initComponent(this.ordermadeSeihinEditElement);
    }
}

// 外部に公開するための定義
export default OrdermadeSeihinEdit;

// ①画面の読み込みが完了するとOrdermadeSeihinEdit.initComponentが動く
window.addEventListener('DOMContentLoaded', (event) => {
    OrdermadeSeihinEdit.initComponent();
});
