import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";


export default class RootMenu {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=RootMenu]").forEach((element) => {
            new RootMenu(element, parentElement);
        });
    }

    private rootMenuElement: HTMLElement;                                     // 基準要素
    private parentElement: HTMLElement;

    private constructor(rootMenuElement: HTMLElement, parentElement: HTMLElement) {
        this.rootMenuElement = rootMenuElement;
        this.parentElement = parentElement;

        this.rootMenuElement.addEventListener('show.bs.modal', event => this.handleShowBsModal(event));
    }

    private handleShowBsModal(event: any) {
        let relatedTarget = ObjectUtils.require(event.relatedTarget, HTMLButtonElement);
        let bodyUrl = StringUtils.require(relatedTarget.dataset["bodyUrl"]);
        let modalBody = ObjectUtils.require(this.rootMenuElement.querySelector(".modal-body"), HTMLElement);

        fetch(bodyUrl)
        .then((response) => response.text())
        .then((text) => {
            modalBody.innerHTML = text;
        });
    }

}
