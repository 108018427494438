import ObjectUtils from "../lib/ObjectUtils";

class OrdermadeSeihinSeihinId {
    static initComponent(parentElement: HTMLElement) {

        parentElement.querySelectorAll<HTMLInputElement>("[data-action=OrdermadeSeihinSeihinId]").forEach((element) => {
            new OrdermadeSeihinSeihinId(element, parentElement);
        });
    }

    private ordermadeSeihinSeihinIdElement: HTMLInputElement;

    private parentElement: HTMLElement;

    private constructor(ordermadeSeihinSeihinIdElement: HTMLInputElement, parentElement: HTMLElement) {
        this.ordermadeSeihinSeihinIdElement = ordermadeSeihinSeihinIdElement;
        this.parentElement = parentElement;

        this.ordermadeSeihinSeihinIdElement.addEventListener('change', event => this.handleChange(event));
    }

    private handleChange(event: Event) {
        let kojoIdElement = ObjectUtils.require(this.parentElement.querySelector("#kojoId"), HTMLSelectElement);
        let katsuraShuruiIdElement = ObjectUtils.require(this.parentElement.querySelector("#katsuraShuruiId"), HTMLSelectElement);
        let seihinIdElement = ObjectUtils.require(this.parentElement.querySelector("#seihinId"), HTMLInputElement);
        let chumonId = ObjectUtils.requireString(this.ordermadeSeihinSeihinIdElement.dataset["chumonId"]);

        let kojoKigo = ObjectUtils.requireString(kojoIdElement.options[kojoIdElement.selectedIndex].dataset["seihinIdKigo"]);
        let katsuraShurui = ObjectUtils.requireString(katsuraShuruiIdElement.options[katsuraShuruiIdElement.selectedIndex].dataset["seihinIdKigo"]);
        seihinIdElement.value = chumonId + kojoKigo + katsuraShurui;
    }
}

export default OrdermadeSeihinSeihinId;