import DateUtils from "../lib/DateUtils";
import ObjectUtils from "../lib/ObjectUtils";

class DenwaRenrakuKekkaSet {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=DenwaRenrakuKekkaSet]").forEach((element) => {
            new DenwaRenrakuKekkaSet(element, parentElement);
        });
    }

    private denwaRenrakuKekkaSetElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(denwaRenrakuKekkaSetElement: HTMLElement, parentElement: HTMLElement) {
        this.denwaRenrakuKekkaSetElement = denwaRenrakuKekkaSetElement;
        this.parentElement = parentElement;

        this.denwaRenrakuKekkaSetElement.addEventListener("click", event => this.handleChange(event));
    }

    private handleChange(event: any) {
        // ボタンの文字列を取得（HTMLButtonElement）
        let currentTarget = ObjectUtils.require(event.currentTarget, HTMLButtonElement);
        let currentTargetText = currentTarget.textContent;

        // 備考を取得（HTMLTextAreaElement）
        let inputTarget = ObjectUtils.require(this.parentElement.querySelector("#denwaRenrakuKekkaSet"), HTMLTextAreaElement);

        // 備考に設定する情報を取得
        let nowDateTime = DateUtils.toDateTimeString(DateUtils.now());
        let currentUser = inputTarget.dataset["currentUser"];
        let tempText = "";
        let lastChar = inputTarget.value.substring(inputTarget.value.length - 1);

        if (lastChar !== "\n") {
            tempText = "\n";
        }

        // 備考に取得した文字列をセットする
        tempText = inputTarget.value + tempText + nowDateTime + " " + currentUser + " " + ObjectUtils.requireString(currentTargetText) + "\n";
        inputTarget.value = tempText;

    }

}

export default DenwaRenrakuKekkaSet;