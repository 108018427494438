import ConfirmSubmitButton from "../action/ConfirmSubmitButton";
import ReloadingModalDialog from "../action/ReloadingModalDialog";
import NumberUtils from "../lib/NumberUtils";
import ObjectUtils from "../lib/ObjectUtils";

class FuzokuhinOrderFuzokuhinKosu {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLSelectElement>("[data-action=FuzokuhinOrderFuzokuhinKosu]").forEach((element) => {
            new FuzokuhinOrderFuzokuhinKosu(element, parentElement);
        });
    }

    private fuzokuhinOrderFuzokuhinKosuElement: HTMLSelectElement;
    private parentElement: HTMLElement;

    private constructor(fuzokuhinOrderFuzokuhinKosuElement: HTMLSelectElement, parentElement: HTMLElement) {
        this.fuzokuhinOrderFuzokuhinKosuElement = fuzokuhinOrderFuzokuhinKosuElement;
        this.parentElement = parentElement;

        this.fuzokuhinOrderFuzokuhinKosuElement.addEventListener('change', event => this.handleChange(event));
    }

    private handleChange(event: Event) {
        let zeikomiTanka = NumberUtils.parse(ObjectUtils.requireString(this.fuzokuhinOrderFuzokuhinKosuElement.dataset["zeikomiTanka"]));
        let kosu = NumberUtils.parse(this.fuzokuhinOrderFuzokuhinKosuElement.value);
        let target = ObjectUtils.requireString(this.fuzokuhinOrderFuzokuhinKosuElement.dataset["target"]);
        let targetElement = ObjectUtils.require(this.parentElement.querySelector(target), HTMLElement);
        targetElement.innerHTML = NumberUtils.currency(zeikomiTanka * kosu);
    }
}

export default FuzokuhinOrderFuzokuhinKosu;
