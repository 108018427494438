export default class ChangeJisshiHiduke {

    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action~=ChangeJisshiHiduke]").forEach((element) => {
            new ChangeJisshiHiduke(element, parentElement);
        });
    }

    private changeJisshiHidukeElement: HTMLInputElement;
    private parentElement: HTMLElement;

    private constructor(changeJisshiHidukeElement: HTMLInputElement, parentElement: HTMLElement) {
        this.changeJisshiHidukeElement = changeJisshiHidukeElement;
        this.parentElement = parentElement;

        this.changeJisshiHidukeElement.addEventListener('change', event => this.handleChange(event));
    }

    private handleChange(event: Event) {
        this.parentElement.querySelectorAll<HTMLInputElement>("#jisshiTesuryoKijunbi,#chumonRyokinKijunbi").forEach(element => {
            element.value = this.changeJisshiHidukeElement.value;
            element.dispatchEvent(new Event("change"));
        });
    }
}