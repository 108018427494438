import ReloadingModalDialog from "../action/ReloadingModalDialog";
import DenwaRenrakuKekkaSet from "./DenwaRenrakuKekkaSet";

class DenwaRenrakuOtaiKirokuModal {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=DenwaRenrakuOtaiKirokuModal]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
            new DenwaRenrakuOtaiKirokuModal(element, parentElement);
        });
    }

    private denwaRenrakuOtaiKirokuModalElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(denwaRenrakuOtaiKirokuModalElement: HTMLElement, parentElement: HTMLElement) {
        this.denwaRenrakuOtaiKirokuModalElement = denwaRenrakuOtaiKirokuModalElement;
        this.parentElement = parentElement;

        this.denwaRenrakuOtaiKirokuModalElement.addEventListener('ReloadingModalDialog:reloaded', event => this.handleReloaded(event));
    }

    private handleReloaded(event: Event) {
        DenwaRenrakuKekkaSet.initComponent(this.denwaRenrakuOtaiKirokuModalElement);
    }
}

export default DenwaRenrakuOtaiKirokuModal;
