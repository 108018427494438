import * as objutils from "../lib/object-utils";

export default class KokyakuMenu {

    public static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=KokyakuMenu]").forEach((element) => {
            new KokyakuMenu(element, parentElement);
        });
    }

    private kokyakuMenuElement: HTMLElement;                                     // 基準要素
    private parentElement: HTMLElement;

    private constructor(kokyakuMenuElement: HTMLElement, parentElement: HTMLElement) {
        this.kokyakuMenuElement = kokyakuMenuElement;
        this.parentElement = parentElement;

        this.kokyakuMenuElement.addEventListener('show.bs.modal', event => this.handleShowBsModal(event));
    }

    handleShowBsModal(event: any) {
        let relatedTarget = objutils.require<HTMLButtonElement>(event.relatedTarget);
        let bodyUrl = objutils.require<string>(relatedTarget.dataset["bodyUrl"]);
        let modalBody = objutils.require<HTMLElement>(this.kokyakuMenuElement.querySelector(".modal-body"));

        fetch(bodyUrl)
        .then((response) => response.text())
        .then((text) => {
            modalBody.innerHTML = text;
        });
    }

}
