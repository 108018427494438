import ConfirmSubmitButton from "../action/ConfirmSubmitButton";
import ReloadingModalDialog from "../action/ReloadingModalDialog";
import NumberUtils from "../lib/NumberUtils";
import ObjectUtils from "../lib/ObjectUtils";

class SemiOrderFuzokuhinKosu {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLSelectElement>("[data-action=SemiOrderFuzokuhinKosu]").forEach((element) => {
            new SemiOrderFuzokuhinKosu(element, parentElement);
        });
    }

    private chumonFuzokuhinKosuElement: HTMLSelectElement;
    private parentElement: HTMLElement;

    private constructor(chumonFuzokuhinKosuElement: HTMLSelectElement, parentElement: HTMLElement) {
        this.chumonFuzokuhinKosuElement = chumonFuzokuhinKosuElement;
        this.parentElement = parentElement;

        this.chumonFuzokuhinKosuElement.addEventListener('change', event => this.handleChange(event));
    }

    private handleChange(event: Event) {
        let zeikomiTanka = NumberUtils.parse(ObjectUtils.requireString(this.chumonFuzokuhinKosuElement.dataset["zeikomiTanka"]));
        let kosu = NumberUtils.parse(this.chumonFuzokuhinKosuElement.value);
        let target = ObjectUtils.requireString(this.chumonFuzokuhinKosuElement.dataset["target"]);
        let targetElement = ObjectUtils.require(this.parentElement.querySelector(target), HTMLElement);
        targetElement.innerHTML = NumberUtils.currency(zeikomiTanka * kosu);
    }
}

export default SemiOrderFuzokuhinKosu;
