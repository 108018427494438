import * as objutils from "../lib/object-utils";

// BootStrap の初期化
const bootstrap = require('bootstrap');

class ChumonOtaiKiroku {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=ChumonOtaiKiroku]").forEach((element) => {
            new ChumonOtaiKiroku(element, parentElement);
        });
    }

    private chumonOtaiKirokuElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(chumonOtaiKirokuElement: HTMLElement, parentElement: HTMLElement) {
        this.chumonOtaiKirokuElement = chumonOtaiKirokuElement;
        this.parentElement = parentElement;

        this.chumonOtaiKirokuElement.addEventListener('show.bs.modal', event => this.handleShowBsModal(event));
    }

    private handleShowBsModal(event: any) {
        let relatedTarget = objutils.require<HTMLButtonElement>(event.relatedTarget);
        let bodyUrl = objutils.require<string>(relatedTarget.dataset["bodyUrl"]);
        let modalBody = objutils.require<HTMLElement>(this.chumonOtaiKirokuElement.querySelector(".modal-body"));

        fetch(bodyUrl)
        .then((response) => response.text())
        .then((text) => {
            modalBody.innerHTML = text;
        });
    }
}

export default ChumonOtaiKiroku;