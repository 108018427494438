import BooleanUtils from "../lib/BooleanUtils";
import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";

export default class ChangeYoyakuShurui {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLSelectElement>("[data-action=ChangeYoyakuShurui]").forEach((element) => {
            new ChangeYoyakuShurui(element, parentElement);
        });
    }

    private changeYoyakuShuruiElement: HTMLSelectElement;                                     // 基準要素
    private parentElement: HTMLElement;                                                     // 親要素

    private constructor(changeYoyakuShuruiElement: HTMLSelectElement, parentElement: HTMLElement) {
        this.changeYoyakuShuruiElement = changeYoyakuShuruiElement;
        this.parentElement = parentElement;

        // 予約種類を変更した時にイベントを発生させる
        this.changeYoyakuShuruiElement.addEventListener('change', event => this.handleChange(event));

        this.handleChange(null);
    }

    private handleChange(event: any): any {

        // 注文 ID の制御
        let displayChumonId = BooleanUtils.parse(StringUtils.require(this.changeYoyakuShuruiElement.selectedOptions[0].dataset["displayChumonId"]));
        this.parentElement.querySelectorAll("#displayChumonId").forEach((element) => {
            if (displayChumonId) {
                element.classList.remove("d-none");
            } else {
                element.classList.add("d-none");
            }
        });

        // 配送品の制御
        let displayHaisohin = BooleanUtils.parse(StringUtils.require(this.changeYoyakuShuruiElement.selectedOptions[0].dataset["displayHaisohin"]));
        this.parentElement.querySelectorAll("#displayHaisohin").forEach((element) => {
            if (displayHaisohin) {
                element.classList.remove("d-none");
            } else {
                element.classList.add("d-none");
            }
        });
    }
}
