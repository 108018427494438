import ConfirmSubmitButton from "../action/ConfirmSubmitButton";
import ReloadingModalDialog from "../action/ReloadingModalDialog";

class DairitenShow {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=DairitenShow]").forEach((element) => {
            new DairitenShow(element);
        });
    }

    private dairitenShowElement: HTMLElement;

    private constructor(dairitenShowElement: HTMLElement) {
        this.dairitenShowElement = dairitenShowElement;

        ReloadingModalDialog.initComponent(this.dairitenShowElement);
        ConfirmSubmitButton.initComponent(this.dairitenShowElement);
    }
}

export default DairitenShow;

window.addEventListener('DOMContentLoaded', (event) => {
    DairitenShow.initComponent();
});
