import * as objutils from "../lib/object-utils";

const bootstrap = require('bootstrap');

class YoyakuHaisohinToroku {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=YoyakuHaisohinToroku]").forEach((element) => {
            new YoyakuHaisohinToroku(element, parentElement);
        });
    }

    private yoyakuHaisohinTorokuElement: HTMLElement;                                     // 基準要素
    private parentElement: HTMLElement;

    private constructor(yoyakuHaisohinTorokuElement: HTMLElement, parentElement: HTMLElement) {
        this.yoyakuHaisohinTorokuElement = yoyakuHaisohinTorokuElement;
        this.parentElement = parentElement;

        this.yoyakuHaisohinTorokuElement.addEventListener('click', (event) => this.handleHaisohinTorokuClick(event));
    }

    private handleHaisohinTorokuClick(event: any) {
        let haisohinModalForm = objutils.require<HTMLFormElement>(this.parentElement.querySelector<HTMLFormElement>("#haisohinModalForm"));
        fetch(haisohinModalForm.action, {
            method: haisohinModalForm.method,
            body: new FormData(haisohinModalForm)
        })
        .then((response) => response.text())
        .then((text) => {
            // TODO document を使っているので見直したい。
            let haisohinIchiranTableBody = objutils.require<HTMLTableElement>(document.querySelector<HTMLFormElement>("#haisohinIchiranTableBody"));
            haisohinIchiranTableBody.innerHTML = text;
            this.getModal().hide();
        });

    }

    // BootStrap のモーダルインスタンスを作成
    private getModal(): any {
        let modal = bootstrap.Modal.getInstance(this.parentElement);
        if (modal == null) {
            modal = new bootstrap.Modal(this.parentElement);
        }
        return modal;
    }
}

export default YoyakuHaisohinToroku;