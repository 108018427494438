import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";

/**
 * ReloadingModalDialog で表示した Modal のコンテンツの submit ボタンに設定する。
 * fetch API を使って関連付けられた form の送信を行う。
 * コンテンツを取得したら .modal-content を置き換える。
 * リダイレクトしたら、location.assign() を使ってリダイレクトする。
 *
 * 指定する属性
 * data-action="ReloadingModalSubmitButton"  アクションコンポーネント
 * form="exampleForm"                        form の id。※オプション
 *
 * ※form が指定してあり、method が post のときにしか対応していない。必要に応じて拡張していくこと。
 */
class ReloadingModalSubmitButton {
    static initComponent(reloadingModalDialogElement: HTMLElement) {
        reloadingModalDialogElement.querySelectorAll<HTMLInputElement>("[data-action=ReloadingModalSubmitButton]").forEach((element) => {
            new ReloadingModalSubmitButton(element, reloadingModalDialogElement);
        });
    }

    private reloadingModalSubmitButtonElement: HTMLInputElement;
    private reloadingModalDialogElement: HTMLElement;

    private constructor(reloadingModalSubmitButtonElement: HTMLInputElement, reloadingModalDialogElement: HTMLElement) {
        this.reloadingModalSubmitButtonElement = reloadingModalSubmitButtonElement;
        this.reloadingModalDialogElement = reloadingModalDialogElement;

        this.reloadingModalSubmitButtonElement.addEventListener("click", event => this.handleClick(event));
    }

    private async handleClick(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        let form = ObjectUtils.require(this.reloadingModalSubmitButtonElement.form, HTMLFormElement);

        let formData = new FormData(form);
        let name = this.reloadingModalSubmitButtonElement.name;

        if (StringUtils.isNotBlank(name)) {
            let value = this.reloadingModalSubmitButtonElement.value;
            formData.append(name, value);
        }

        let response = await fetch(form.action, {method: form.method, body: formData});

        if (response.redirected) {
            let url = new URL(response.url);
            if (url.searchParams.has("_fragment")) {
                let hash = url.searchParams.get("_fragment");
                if (hash) {
                    url.hash = "#" + hash;
                }
                url.searchParams.delete("_fragment");
            }

            location.assign(url.toString());
            location.reload();

        } else {
            let modalContent = ObjectUtils.require<HTMLElement>(this.reloadingModalDialogElement.querySelector(".modal-dialog"), HTMLElement);

            modalContent.innerHTML = await response.text();

            ReloadingModalSubmitButton.initComponent(this.reloadingModalDialogElement);

            let event = new CustomEvent("ReloadingModalDialog:reloaded");
            this.reloadingModalDialogElement.dispatchEvent(event);
        }
    }
}

export default ReloadingModalSubmitButton;
