import ChangeSagyoKomoku from "../action/ChangeSagyoKomoku";
import ChangeSeisanduki from "../action/ChangeSeisanduki";
import ReloadingModalDialog from "../action/ReloadingModalDialog";

class SemiOrderJisshiEditModal {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=SemiOrderJisshiEditModal]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
            new SemiOrderJisshiEditModal(element, parentElement);
        });
    }

    private semiOrderJisshEditModalElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(semiOrderJisshEditModalElement: HTMLElement, parentElement: HTMLElement) {
        this.semiOrderJisshEditModalElement = semiOrderJisshEditModalElement;
        this.parentElement = parentElement;

        this.semiOrderJisshEditModalElement.addEventListener('ReloadingModalDialog:reloaded', event => this.handleReloaded(event));
    }

    private handleReloaded(event: Event) {
        ChangeSeisanduki.initComponent(this.semiOrderJisshEditModalElement);
        ChangeSagyoKomoku.initComponent(this.semiOrderJisshEditModalElement);
    }
}

export default SemiOrderJisshiEditModal;
