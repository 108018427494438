import ClearForm from "../action/ClearForm";
import YoyakuMenu from "./YoyakuMenu";

const bootstrap = require('bootstrap');

export default class YoyakuIndex {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=YoyakuIndex]").forEach((element) => {
            new YoyakuIndex(element);
        });
    }

    private yoyakuIndexElement: HTMLElement;                                     // 基準要素

    private constructor(yoyakuIndexElement: HTMLElement) {
        this.yoyakuIndexElement = yoyakuIndexElement;

        YoyakuMenu.initComponent(this.yoyakuIndexElement);
        ClearForm.initComponent(this.yoyakuIndexElement);
    }
}
