import ReloadingModalDialog from "../action/ReloadingModalDialog";

class ShuriOrderJisshiShowModal {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=ShuriOrderJisshiShowModal]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
            new ShuriOrderJisshiShowModal(element, parentElement);
        });
    }

    private shuriOrderJisshiShowModalElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(shuriOrderJisshiShowModalElement: HTMLElement, parentElement: HTMLElement) {
        this.shuriOrderJisshiShowModalElement = shuriOrderJisshiShowModalElement;
        this.parentElement = parentElement;

        this.shuriOrderJisshiShowModalElement.addEventListener('ReloadingModalDialog:reloaded', event => this.handleReloaded(event));
    }

    private handleReloaded(event: Event) {
        ReloadingModalDialog.initComponent(this.shuriOrderJisshiShowModalElement);
    }
}

export default ShuriOrderJisshiShowModal;
