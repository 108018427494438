import ReloadingModalDialog from "../action/ReloadingModalDialog";

class KokyakuOtaiKirokuIndex {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=KokyakuOtaiKirokuIndex]").forEach((element) => {
            new KokyakuOtaiKirokuIndex(element);
        });
    }

    private kokyakuOtaiKirokuIndexElement: HTMLElement;

    private constructor(kokyakuOtaiKirokuIndexElement: HTMLElement) {
        this.kokyakuOtaiKirokuIndexElement = kokyakuOtaiKirokuIndexElement;

        ReloadingModalDialog.initComponent(this.kokyakuOtaiKirokuIndexElement);
    }
}

export default KokyakuOtaiKirokuIndex;

window.addEventListener('DOMContentLoaded', (event) => {
    KokyakuOtaiKirokuIndex.initComponent();
});
