import ChangeSagyoKomoku from "../action/ChangeSagyoKomoku";
import ChangeSeisanduki from "../action/ChangeSeisanduki";

class KokyakuJisshiNew {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=KokyakuJisshiNew]").forEach((element) => {
            new KokyakuJisshiNew(element);
        });
    }

    private kokyakuJisshiNewElement: HTMLElement;

    private constructor(kokyakuJisshiNewElement: HTMLElement) {
        this.kokyakuJisshiNewElement = kokyakuJisshiNewElement;

        ChangeSagyoKomoku.initComponent(this.kokyakuJisshiNewElement);
        ChangeSeisanduki.initComponent(this.kokyakuJisshiNewElement);

    }
}

export default KokyakuJisshiNew;

window.addEventListener('DOMContentLoaded', (event) => {
    KokyakuJisshiNew.initComponent();
});