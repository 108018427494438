import ClickTrigger from "../action/ClickTrigger";
import SeisanCheckIndexShoninCheckbox from "./SeisanCheckIndexShoninCheckbox";
import SeisanCheckIndexUpdateButton from "./SeisanCheckIndexUpdateButton";
import SeisanCheckIndexUpdateButtonEnabler from "./SeisanCheckIndexUpdateButtonEnabler";
import ConfirmSubmitButton from "../action/ConfirmSubmitButton";

export default class SeisanCheckIndex {

    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action~=SeisanCheckIndex]").forEach((element) => {
            new SeisanCheckIndex(element);
        });
    }

    private seisanCheckIndexElement: HTMLElement;

    private constructor(seisanCheckIndexElement: HTMLElement) {
        this.seisanCheckIndexElement = seisanCheckIndexElement;

        SeisanCheckIndexShoninCheckbox.initComponent(this.seisanCheckIndexElement);
        SeisanCheckIndexUpdateButtonEnabler.initComponent(this.seisanCheckIndexElement);
        SeisanCheckIndexUpdateButton.initComponent(this.seisanCheckIndexElement);
        ClickTrigger.initComponent(this.seisanCheckIndexElement);
        ConfirmSubmitButton.initComponent(this.seisanCheckIndexElement);
    }
}