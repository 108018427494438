import ChangeYoyakuShurui from "./ChangeYoyakuShurui";
import YoyakuHaisohin from "./YoyakuHaisohin";
import YoyakuShuruiKubun from "./YoyakuShuruiKubun";

class YoyakuNew {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=YoyakuNew]").forEach((element) => {
            new YoyakuNew(element);
        });
    }

    private yoyakuNewElement: HTMLElement;

    private constructor(yoyakuNewElement: HTMLElement) {
        this.yoyakuNewElement = yoyakuNewElement;

        YoyakuHaisohin.initComponent(this.yoyakuNewElement);
        YoyakuShuruiKubun.initComponent(this.yoyakuNewElement);
        ChangeYoyakuShurui.initComponent(this.yoyakuNewElement);
    }

}

window.addEventListener('DOMContentLoaded', (event) => {
    YoyakuNew.initComponent();
});