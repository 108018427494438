import "./dairiten/DairitenShow";
import "./kokyakujisshi/KokyakuJisshiNew";
import "./kokyakujisshi/KokyakuJisshiEdit";
import "./kokyakujisshi/KokyakuJisshiShow";
import "./kokyakuotaikiroku/KokyakuOtaiKirokuIndex";
import "./ordermadeseihin/OrdermadeSeihinNew";
import "./ordermadeseihin/OrdermadeSeihinEdit";
import "./shinki-order/ChumonShow";
import "./shinki-order/ChumonNew";
import "./sokin/SokinIndex";
import "./toiawase/toiawase-index";
import "./yoyaku/YoyakuNew";
import "./yoyaku/YoyakuEdit";
import DenwaRenrakuIndex from "./denwarenraku/DenwaRenrakuIndex";
import ShuriOrderShow from "./shuriorder/ShuriOrderShow";
import SemiOrderEdit from "./semiorder/SemiOrderEdit";
import SemiOrderNew from "./semiorder/SemiOrderNew";
import ChumonNew from "./shinki-order/ChumonNew";
import ChumonShow from "./shinki-order/ChumonShow";
import ChumonEdit from "./shinki-order/ChumonEdit";
import KokyakuShow from "./kokyaku/KokyakuShow";
import OtaiKirokuEdit from "./otaikiroku/OtaiKirokuEdit";
import ChumonSheetIndex from "./chumonsheet/ChumonSheetIndex";
import ChumonIndex from "./chumon/ChumonIndex";
import KokyakuImageIndex from "./kokyakuimage/KokyakuImageIndex";
import SemiOrderShow from "./semiorder/SemiOrderShow";
import FuzokuhinOrderNew from "./fuzokuhinorder/FuzokuhinOrderNew";
import FuzokuhinOrderShow from "./fuzokuhinorder/FuzokuhinOrderShow";
import FuzokuhinOrderEdit from "./fuzokuhinorder/FuzokuhinOrderEdit";
import KokyakuIndex from "./kokyaku/KokyakuIndex";
import KokyakuNew from "./kokyaku/KokyakuNew";
import KokyakuEdit from "./kokyaku/KokyakuEdit";
import OrdermadeSeihinIndex from "./ordermadeseihin/OrdermadeSeihinIndex";
import ShuriOrderSeihinNew from "./shuriorderseihin/ShuriOrderSeihinNew";
import ShuriOrderSeihinEdit from "./shuriorderseihin/ShuriOrderSeihinEdit";
import YoyakuIndex from "./yoyaku/YoyakuIndex";
import HoshoMoshikomiOrderEdit from "./hoshomoshikomiorder/HoshoMoshikomiOrderEdit";
import HoshoMoshikomiOrderShow from "./hoshomoshikomiorder/HoshoMoshikomiOrderShow";
import ShuriOrderNew from "./shuriorder/ShuriOrderNew";
import ShuriOrderEdit from "./shuriorder/ShuriOrderEdit";
import RootIndex from "./root/RootIndex";
import KokyakuMailNew from "./kokyakumail/KokyakuMailNew";
import HoshoMoshikomiOrderNew from "./hoshomoshikomiorder/HoshoMoshikomiOrderNew";
import ClientCheckIndex from "./clientcheck/ClientCheckIndex";
import ClientCheckNew from "./clientcheck/ClientCheckNew";
import ClientCheckEdit from "./clientcheck/ClientCheckEdit";
import SeisanCheckIndex from "./seisancheck/SeisanCheckIndex";


window.addEventListener('DOMContentLoaded', (event) => {

    ClientCheckIndex.initComponent();
    ClientCheckNew.initComponent();
    ClientCheckEdit.initComponent();
    ChumonIndex.initComponent();
    ChumonShow.initComponent();
    ChumonNew.initComponent();
    ChumonEdit.initComponent();
    ChumonSheetIndex.initComponent();
    DenwaRenrakuIndex.initComponent();
    FuzokuhinOrderNew.initComponent();
    FuzokuhinOrderShow.initComponent();
    FuzokuhinOrderEdit.initComponent();
    HoshoMoshikomiOrderShow.initComponent();
    HoshoMoshikomiOrderNew.initComponent();
    HoshoMoshikomiOrderEdit.initComponent();
    KokyakuIndex.initComponent();
    KokyakuShow.initComponent();
    KokyakuNew.initComponent();
    KokyakuEdit.initComponent();
    KokyakuImageIndex.initComponent();
    KokyakuMailNew.initComponent();
    OrdermadeSeihinIndex.initComponent();
    ShuriOrderNew.initComponent();
    ShuriOrderEdit.initComponent();
    ShuriOrderShow.initComponent();
    ShuriOrderSeihinNew.initComponent();
    ShuriOrderSeihinEdit.initComponent();
    SeisanCheckIndex.initComponent();
    SemiOrderShow.initComponent();
    SemiOrderNew.initComponent();
    SemiOrderEdit.initComponent();
    OtaiKirokuEdit.initComponent();
    YoyakuIndex.initComponent();
    RootIndex.initComponent();
});
