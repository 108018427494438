import BooleanUtils from "../lib/BooleanUtils";
import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";

class ChangeSagyoKomoku {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLSelectElement>("[data-action=ChangeSagyoKomoku]").forEach((element) => {
            new ChangeSagyoKomoku(element, parentElement);
        });
    }

    private changeSagyoKomokuElement: HTMLSelectElement;
    private parentElement: HTMLElement;

    private constructor(changeSagyoKomokuElement: HTMLSelectElement, parentElement: HTMLElement) {
        this.changeSagyoKomokuElement = changeSagyoKomokuElement;
        this.parentElement = parentElement;

        // 作業項目を変更したら change イベントを発火する
        this.changeSagyoKomokuElement.addEventListener("change", event => this.handleChange(event));

        this.handleChange(null);
    }

    private handleChange(event: any) {

        // 注文 ID の制御
        let displayChumonId = BooleanUtils.parse(StringUtils.require(this.changeSagyoKomokuElement.selectedOptions[0].dataset["displayChumonId"]));
        this.parentElement.querySelectorAll("#displayChumonId").forEach((element) => {
            if (displayChumonId) {
                element.classList.remove("d-none");
            } else {
                element.classList.add("d-none");
            }
        });

        // 製品 ID の制御
        let displaySeihinId = BooleanUtils.parse(StringUtils.require(this.changeSagyoKomokuElement.selectedOptions[0].dataset["displaySeihinId"]));
        this.parentElement.querySelectorAll("#displaySeihinId").forEach((element) => {
            if (displaySeihinId) {
                element.classList.remove("d-none");
            } else {
                element.classList.add("d-none");
            }
        });

        // 当日注文番号の制御
        let displayTojitsuChumonBango = BooleanUtils.parse(StringUtils.require(this.changeSagyoKomokuElement.selectedOptions[0].dataset["displayTojitsuChumonBango"]));
        this.parentElement.querySelectorAll("#displayTojitsuChumonBango").forEach((element) => {
            if (displayTojitsuChumonBango) {
                element.classList.remove("d-none");
            } else {
                element.classList.add("d-none");
            }
        });

        // 精算額の制御
        let displaySeisangaku = BooleanUtils.parse(StringUtils.require(this.changeSagyoKomokuElement.selectedOptions[0].dataset["displaySeisangaku"]));
        this.parentElement.querySelectorAll("#displaySeisangaku").forEach((element) => {
            if (displaySeisangaku) {
                element.classList.remove("d-none");
            } else {
                element.classList.add("d-none");
            }
        });

        // 参照代理店受け取り金額の制御
        let displaySanshoDairitenUketorikingaku = BooleanUtils.parse(StringUtils.require(this.changeSagyoKomokuElement.selectedOptions[0].dataset["displaySanshoDairitenUketorikingaku"]));
        this.parentElement.querySelectorAll("#displaySanshoDairitenUketorikingaku").forEach((element) => {
            if (displaySanshoDairitenUketorikingaku) {
                element.classList.remove("d-none");
            } else {
                element.classList.add("d-none");
            }
        });

        // 代理店でご購入頂いたヘアケア剤の報告の制御
        let displayDairitenKonyuHairCareHokoku = BooleanUtils.parse(StringUtils.require(this.changeSagyoKomokuElement.selectedOptions[0].dataset["displayDairitenKonyuHairCareHokoku"]));
        this.parentElement.querySelectorAll("#displayDairitenKonyuHairCareHokoku").forEach((element) => {
            if (displayDairitenKonyuHairCareHokoku) {
                element.classList.remove("d-none");
            } else {
                element.classList.add("d-none");
            }
        });

        // 手数料
        let displayTesuryo = BooleanUtils.parse(StringUtils.require(this.changeSagyoKomokuElement.selectedOptions[0].dataset["displayTesuryo"]));
        this.parentElement.querySelectorAll("#displayTesuryo").forEach((element) => {
            if (displayTesuryo) {
                element.classList.remove("d-none");
            } else {
                element.classList.add("d-none");
            }
        });
    }
}

export default ChangeSagyoKomoku;