import ChangeSagyoKomoku from "../action/ChangeSagyoKomoku";
import ChangeSeisanduki from "../action/ChangeSeisanduki";
import ConfirmSubmitButton from "../action/ConfirmSubmitButton";
import ReloadingModalDialog from "../action/ReloadingModalDialog";

class SemiOrderJisshiNewModal {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=SemiOrderJisshiNewModal]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
            new SemiOrderJisshiNewModal(element, parentElement);
        });
    }

    private semiOrderJisshNewModalElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(semiOrderJisshNewModalElement: HTMLElement, parentElement: HTMLElement) {
        this.semiOrderJisshNewModalElement = semiOrderJisshNewModalElement;
        this.parentElement = parentElement;

        this.semiOrderJisshNewModalElement.addEventListener('ReloadingModalDialog:reloaded', event => this.handleReloaded(event));
    }

    private handleReloaded(event: Event) {
        ChangeSeisanduki.initComponent(this.semiOrderJisshNewModalElement);
        ChangeSagyoKomoku.initComponent(this.semiOrderJisshNewModalElement);
    }
}

export default SemiOrderJisshiNewModal;
