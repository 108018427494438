import ClearForm from "../action/ClearForm";

const bootstrap = require('bootstrap');

export default class OrdermadeSeihinIndex {

    public static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=OrdermadeSeihinIndex]").forEach((element) => {
            new OrdermadeSeihinIndex(element);
        });
    }

    private seihinIndexElement: HTMLElement;                                     // 基準要素

    private constructor(seihinIndexElement: HTMLElement) {
        this.seihinIndexElement = seihinIndexElement;

        ClearForm.initComponent(this.seihinIndexElement);
    }
}
