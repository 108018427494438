import ConfirmSubmitButton from "../action/ConfirmSubmitButton";
import SetRenrakuKekka from "./SetRenrakuKekka";

class OtaiKirokuEdit {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=OtaiKirokuEdit]").forEach((element) => {
            new OtaiKirokuEdit(element);
        });
    }

    private otaiKirokuEditElement: HTMLElement;

    private constructor(otaiKirokuEditElement: HTMLElement) {
        this.otaiKirokuEditElement = otaiKirokuEditElement;

        SetRenrakuKekka.initComponent(this.otaiKirokuEditElement);
        ConfirmSubmitButton.initComponent(this.otaiKirokuEditElement);
    }
}

export default OtaiKirokuEdit;
