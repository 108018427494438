export default class FormatTelKeitai {

    public static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=FormatTelKeitai]").forEach((element) => {
            new FormatTelKeitai(element, parentElement);
        });
    }

    private formatTelKeitaiElement: HTMLInputElement;                                     // 基準要素
    private parentElement: HTMLElement;

    private constructor(formatTelKeitaiElement: HTMLInputElement, parentElement: HTMLElement) {
        this.formatTelKeitaiElement = formatTelKeitaiElement;
        this.parentElement = parentElement;

        this.formatTelKeitaiElement.addEventListener("blur", (ev) => this.handleBlur(ev));
    }

    private handleBlur(ev: Event) {
        let value = this.formatTelKeitaiElement.value;

        // 11桁の数字であればハイフンを追加
        if (/^\d{11}$/.test(value)) {
            this.formatTelKeitaiElement.value = value.slice(0, 3) + "-" + value.slice(3, 7) + "-" + value.slice(7, 11);
        }
    }
}
