import ClientCheckHighlightText from "./ClientCheckHighlightText";
import ClientCheckCopyValue from "./ClientCheckCopyValue";

export default class ClientCheckIndex {

    public static initComponent() {
        document.querySelectorAll<HTMLElement>("#clientCheckIndex").forEach((element) => {
            new ClientCheckIndex(element);
        });
    }

    private clientCheckIndex: HTMLElement;                                     // 基準要素

    private constructor(clientCheckIndex: HTMLElement) {
        this.clientCheckIndex = clientCheckIndex;

        // 問合せと一致する顧客項目の文字の色を変更する
        ClientCheckHighlightText.initComponent(this.clientCheckIndex);

        // 検索項目に、クリックされた要素の値を入力する
        ClientCheckCopyValue.initComponent(this.clientCheckIndex);
    }

}
