import SokinSosaigakuKeisan from "./SokinSosaigakuKeisan";

/**
 * 送金一覧コンポーネント
 */
class SokinIndex {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=SokinIndex]").forEach((element) => {
            new SokinIndex(element);
        });
    }

    private sokinIndexElement: HTMLElement;

    private constructor(sokinIndexElement: HTMLElement) {
        this.sokinIndexElement = sokinIndexElement;

        SokinSosaigakuKeisan.initComponent(this.sokinIndexElement);
    }
}

export default SokinIndex;

window.addEventListener('DOMContentLoaded', (event) => {
    SokinIndex.initComponent();
});
