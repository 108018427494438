import ShuriOrderSeihinIppanPlusRemi from "./ShuriOrderSeihinIppanPlusRemi";


class ShuriOrderSeihinNew {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=ShuriOrderSeihinNew]").forEach((element) => {
            new ShuriOrderSeihinNew(element);
        });
    }

    private shuriOrderSeihinNewElement: HTMLElement;

    private constructor(shuriOrderSeihinNewElement: HTMLElement) {
        this.shuriOrderSeihinNewElement = shuriOrderSeihinNewElement;

        ShuriOrderSeihinIppanPlusRemi.initComponent(this.shuriOrderSeihinNewElement);
    }
}

// 外部に公開するための定義
export default ShuriOrderSeihinNew;