import DateUtils from "../lib/DateUtils";
import ObjectUtils from "../lib/ObjectUtils";

class SetRenrakuKekka {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=SetRenrakuKekka]").forEach((element) => {
            new SetRenrakuKekka(element, parentElement);
        });
    }

    private setRenrakuKekkaElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(setRenrakuKekkaElement: HTMLElement, parentElement: HTMLElement) {
        this.setRenrakuKekkaElement = setRenrakuKekkaElement;
        this.parentElement = parentElement;

        this.setRenrakuKekkaElement.addEventListener("click", event => this.handleChange(event));
    }

    private handleChange(event: any) {

        // ボタンの文字列を取得（HTMLButtonElement）
        let textContent = this.setRenrakuKekkaElement.textContent;

        // 備考を取得（HTMLTextAreaElement）
        let bikoElement = ObjectUtils.require(this.parentElement.querySelector("#biko"), HTMLTextAreaElement);
        let kinyushaElement =  ObjectUtils.require(this.parentElement.querySelector("#kinyusha"), HTMLSelectElement);

        // 備考に設定する情報を取得
        let nowDateTime = DateUtils.toDateTimeString(DateUtils.now());
        let currentUser = kinyushaElement.selectedOptions[0].text;
        let tempText = "";
        let lastChar = bikoElement.value.substring(bikoElement.value.length - 1);

        if (bikoElement.value.length > 0 && lastChar !== "\n") {
            tempText = "\n";
        }
        // 備考に取得した文字列をセットする
        tempText = bikoElement.value + tempText + nowDateTime + " " + currentUser + " " + ObjectUtils.requireString(textContent) + "\n";
        bikoElement.value = tempText;
    }

}

export default SetRenrakuKekka;