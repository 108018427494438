import ChangeMainEmailType from "../action/ChangeMainEmailType";
import ChangeMainTelType from "../action/ChangeMainTelType";
import FormatTelKeitai from "../action/FormatTelKeitai";

export default class ClientCheckNew {

    public static initComponent() {
        document.querySelectorAll<HTMLElement>("#clientCheckNew").forEach((element) => {
            new ClientCheckNew(element);
        });
    }

    private clientCheckNewElement: HTMLElement;

    private constructor(clientCheckNewElement: HTMLElement) {
        this.clientCheckNewElement = clientCheckNewElement;

        ChangeMainTelType.initComponent(this.clientCheckNewElement);
        ChangeMainEmailType.initComponent(this.clientCheckNewElement);
        FormatTelKeitai.initComponent(this.clientCheckNewElement);
    }
}
