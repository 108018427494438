import ToiawaseIndexDelete from "./toiawase-index-delete";

class ToiawaseIndex {
    toiawaseIndexElement: HTMLElement;                                     // 基準要素

    constructor(toiawaseIndexElement: HTMLElement) {
        this.toiawaseIndexElement = toiawaseIndexElement;

        this.toiawaseIndexElement.querySelectorAll<HTMLInputElement>("[data-action=delete]").forEach((element) => {
            new ToiawaseIndexDelete(element);
        });
    }
}

export default ToiawaseIndex;

window.addEventListener('DOMContentLoaded', (event) => {
    document.querySelectorAll<HTMLElement>("#toiawaseIndex").forEach((element) => {
        new ToiawaseIndex(element);
    });
});
